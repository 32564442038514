//common styles for main and tools navigation
@media all and (max-width: $nav-breakpoint) {
  //reset bootstrap behaviour on mobile nav should be absolute position
  .navbar-collapse.in {
    overflow-y: initial;
  }
  .navbar-collapse{
    position: absolute;
    right:0;
    top:50px;
  }
  .pv-navbar-main,
  .navbar-tool {
    .navbar-toggle {
      //padding: 9px 10px;
      //margin-top: 3px;
      //margin-right: 0;
      //margin-bottom: 2px;
      &,
      &:focus,
      &:hover {
        //background-color: color(white);
      }
    }

    .navbar-nav {
      overflow: visible;
      float: right;
      width: 160px;
      background-color: color(light-gray);
      //color: color(charcoal);
      border: 1px solid color(white);
      > li {
        &:not(:last-child){border-bottom: 1px solid color(white);}
        & > a,
        & > a.active,
        & > a:focus,
        & > a:visited,
        & > a.active:hover,
        & > a:hover {
          color: color(charcoal)!important;
          transition: font 0.3s ease;
          padding: 10px;
        }
      }

      .dropdown{
        z-index: 10004;
        position: relative;
      }
      > .dropdown.open {
        z-index: 10004;
        position: relative;

        > a,
        > a.active,
        > a:focus,
        > a:visited,
        > a:hover {
          //color: color(white);
          // background-color: color(pv);
        }
      }

      .dropdown-menu {
        z-index: 10014;
        overflow: visible;
        width: 120px;
        //float: none;
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        //font-size: $font-size-base * .8;
        > li {
          & > a,
          & > a:focus,
          & > a:visited {
            //white-space: normal;
            //text-align: left;
            //color: color(charcoal);
          }
          //> a:hover {
          //   background-color: color(pv);
          //  color: color(white);
          //
          //}
        }
      }

      .navbar-collapse {
        //max-height: 100%;
        overflow: visible;
      }

    }
  }


}

//end of @media all and (min-width: $nav-breakpoint) {
