//common styles for main and tools navigation
$z-index: 1000;

.pv-navbar-main,
.navbar-tool {
  font-family: Lato-Regular;
  font-weight: normal;
  //padding-top: 0;
  margin: 0px;
  //font-size: $font-size-base;
  //background-color: transparent;
  //border-color: transparent;
  padding: .6rem 0rem;
  .nav-link {
    transition: all .3s ease-in-out;
  }
  .navbar-header { }
  &.navbar {
    min-height: initial;
  }
  //ul
  .navbar-nav {
    > li {
      > a,
      > a.active,
      > a:focus,
      > a:visited,
      > a:hover {
        //color: color(white);
        //font-size: $font-size-base;
        //transition: .3s font-size;
        //background-color: transparent;
      }
      &.active {
        > a,
        > a:focus,
        > a:visited,
        > a:hover {
          //color: color(charcoal);
          //background-color: transparent;
        }
      }

    }

    //li.dropdown
    //replace caret icon
    > .dropdown {
      .caret {
        display: none;
      }
      > a:after {
        //color: color(light-gray);
        // padding-left: 5px;
        // content: '\f0d7';
        // font-family: FontAwesome;
      }

      &.open {
        > a,
        > a.active,
        > a:focus,
        > a:visited,
        > a:hover {
          //color: color(white);
          //background-color: transparent;
        }
      }
    }

    //ul
    .dropdown-menu {
      background-color: color(light-gray);
      padding: 0px;
      border: 1px solid color(white);
      > li {
        &:not(:last-child){border-bottom: 1px solid color(white);}
        > a{
          padding: 8px 15px;
          white-space: normal;
          text-align: left;
          color: color(charcoal);
          display: block;
          font-size: $font-size-base * .8;
        }

        .active,
        :hover {
          background-color: color(pv);
          color: color(white);
        }

      }

    }

  }

}

.navbar-tool {
  z-index: $z-index + 1;
  .navbar-header {
    z-index: $z-index + 2;
    margin: auto;
  }
  .navbar-nav {
    > li {
      & > a,
      & > a:focus,
      & > a:visited,
      & > a:hover {
        //color: color(charcoal)!important;
        font-size: $font-size-base * .9;
      }
    }
    > .dropdown {
      &.open {
        > a,
        > a.active,
        > a:focus,
        > a:visited,
        > a:hover {
          //color: color(charcoal);
          //background-color: transparent;
        }
      }
    }
    .dropdown-menu {
      //font-size: $font-size-base * .8;
    }
  }
}


//md+ devices
@media all and (min-width: $lg-min) {
  .pv-navbar-main {
    //md+ devices
    .navbar-nav {
      > .active {
        $t:11px;
        $b:4px;
        margin-top: -$t;
        > a {
          // padding-top: 11px + $t - $b;
          padding-top: 8px + $t - $b;
          border-top: $b solid color(pv);
        }
      }

      a.register {
        &:after {
          content: ' | ';
        }
        padding-right: 0;
      }

      a.login {
        font-weight: bold;
        padding-left: 4px;
      }

    }

  }

}
