/*fonts test cases*/
#fonts-test{
  font-size: $font-size-base * 1.5;
}
.Lato-Black{
  font-family: Lato-Black, "Comic Sans MS";
}
.Lato-Bold{
  font-family: Lato-Bold, "Comic Sans MS";
}
.Lato-Regular{
  font-family: Lato-Regular, "Comic Sans MS";
}
.Lato-Light{
  font-family: Lato-Light, "Comic Sans MS";
}
