section {
  &#footer {
    .container {
      font-size: $font-size-base*.8;
      a, a:visited, a:hover {
        color: color(white);
      }
      text-align: center;
      padding-top: 32px;
      padding-bottom: 32px;
      p:last-of-type{
        margin-bottom: 0;
      }
    }

  }
}