.sidebar {
  font-size: 85%;
  .block {
    .block-title {
      font-size: 28px;
      font-family: Lato-Regular;
      color: color(dark-gray);
      padding-bottom: 4px;
      border-bottom: 1px solid color(gray);
      margin-bottom: 8px;
    }
    .node-title {
    }
    .node-info {
      margin-top: 4px;
      font-size: 90%;
    }
    .node-featured-image {
    }
    .node + .node {
      margin-top: 16px;
    }
  }
  .block + .block {
    margin-top: 32px;
  }
  .block.block-popular-posts {
    .node-featured-image {
      margin-top: 8px;
    }
  }
  .block.block-recent-posts {
    .node-featured-image {
      padding-right: 0;
    }
  }
  .block.block-twitter-feed {
    margin-left: -10px;
    margin-right: -10px;
  }

  .popular-posts-widget,
  .recent-posts-widget,
  .search-widget,
  .subscribe-widget,
  .twitter-widget {
    padding-bottom: 30px;
    .subhead-parent {
      border-bottom: 1px solid color(charcoal);
      .subhead {
        font-size: 22px;
        margin: 0px;
        padding-bottom: 10px;

      }
    }
    .subcontent {
      font-size: 14px;
      padding-top: 15px;
      h4 {
        font-size: 18px;
      }
      img {
        padding-top: 5px;
      }
      p {
        margin: 0px;
        padding-bottom: 10px;
      }
    }
  }
}