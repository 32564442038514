/*
VALUES EXERCISE STYLE SHEET
#0F459C blue border
#F7F5F6 grey background
*/
//@import "../vendors/bootstrap/less/variables.less";
//@import "../vendors/bootstrap/less/mixins.less";

// .node h1{color: #3a6ca7; }

[data-plugin='values_exercise'] {
  #main-content {
    section {
      &#pv-hero,
      &#pv-crumb-nav {
        .container {
          padding-top: 64px*.1;
          padding-bottom: 64px*.2;
        }
      }
      &#pv-content {
        .container {
          padding-top: 64px*.5;
          padding-bottom: 64px*.5;
        }
      }
      &#pv-form {
        .container {
          padding-top: 64px*.5;
          padding-bottom: 64px*.5;
        }
      }
    }

    @media (max-width: $xs-max) {
      .tool-container {
        padding-left: 3px;
        padding-right: 3px;
        > .row {
          margin-left: -3px;
          margin-right: -3px;
        }
      }

      .panel {
        border-width: 0px;
        border: 0px none;
      }

      .panel-body {
        // padding: 5px;
      }
    }
    @media (min-width: $sm-min) {
      .panel {
        border-width: 1px;
        border: 1px solid color(pv);
      }

      .panel-body {
        // padding: 20px;
      }
    }

    fieldset {
      clear: both;
      position: relative;
      height: auto;
      margin-bottom: 16px;
      @media (min-width: $sm-min) {
        padding: 16px;
        //border: 1px dashed color(pv);
        border-radius: 5px;
        legend {
          /*reset bootstrap*/
          display: block;
          // width: 100%;
          width: auto;
          padding: 0;
          // margin-bottom: @line-height-computed;
          margin-bottom: 0;
          // font-size: ($font-size-base * 1);
          line-height: inherit;
          //color: @legend-color;
          // border: 0;
          //border: 1px dashed @legend-border-color;
          // border-bottom: 1px solid @legend-border-color;

          /*other changes*/
          background: #fff;
          font-weight: normal;
          //border-radius: @border-radius-base;
          padding: 5px 10px;
        }
      }

    }

    .search-blocks {
      padding: 5px 0px;
      margin-bottom: 0;
      background: none;
      background-color: transparent;
    }

    .form-group.required label:after {
      content: "";
    }
    //.error-block { padding-left: 0;background:none; }
    .error-block.checked {
      background: none;
    }

    .c-divider {
      background: color(light-gray);
      width: 100%;
      height: 2px;
      clear: both;
      margin: 10px 0;
      padding: 0 0 0 0;
    }

    // page specific styles
    .veUserMinimumRequirements .save {
    }
    .veUserCriterias .select {
      .control-label {
        padding-top: 0px;
      }
    }

    #pro-values {
      .container {
        padding-top: 0;
      }
    }
    padding-top: 70px;
    .node-title h1 {
      font-family: Lato-Regular;
      // font-size: $font-size-base * 1.4;
      color: color(charcoal);
    }
    .btn {
      min-width: initial;
      margin-bottom: 20px;
      &:hover {
        color: color(pv);
        background: color(white);
      }
    }


  }
  &[data-controller='ve_values'][data-action='index'] {
    .row-buttons {
      text-align: center;
      .btn {
        max-width: 420px;
        margin: 10px 0;
        display: inline-block;
      }
    }
  }
  &[data-controller='ve_user_criterias'] {
    &[data-action='index'] {
      svg {
        circle.a:hover {
          fill: color(pv);
        }
      }
    }
    &[data-action='select'] {
      .stars {
        &:before, &:after {
          content: "\f005\f005\f005\f005";
          font-family: FontAwesome;
        }

        &.stars-1 {
          &:after {
            content: "\f005";
            font-family: FontAwesome;
          }
        }
        &.stars-2 {
          &:after {
            content: "\f005\f005";
            font-family: FontAwesome;
          }
        }
        &.stars-3 {
          &:after {
            content: "\f005\f005\f005";
            font-family: FontAwesome;
          }
        }
        &.stars-4 {
          &:after {
            content: "\f005\f005\f005\f005";
            font-family: FontAwesome;
          }
        }
      }
    }
  }
  &[data-controller='ve_user_criterias'] {
    &[data-action='finalize'] {
      //.stars {
      //  &.stars-3 {
      //    &:before {
      //      content: "\f005\f005\f005\f005";
      //      font-family: FontAwesome;
      //    }
      //  }
      //  &.stars-4 {
      //    &:before {
      //      content: "\f005\f005\f005\f005";
      //      color: #009e9e;
      //      font-family: FontAwesome;
      //    }
      //  }
      //}
    }
  }
  &[data-controller='ve_user_criteria_evaluations'] {
    //xs devices
    @media all and (max-width: $xs-max) {
      .star-rating {
        display: inline-block;
      }
      .visible-xs-inline-block{
        min-width:130px;
      }

    }

    .title {
      font-family: Lato-Regular;
    }

    .row-role-info {
      p {
        margin-bottom: 12px;
      }
    }

    fieldset {
      line-height: 1;
      h2 {
        margin: 0 0;
        line-height: 28px;
      }
      .form-group {
        margin: 0 0;

        clear: both;
        &:after {
          content: "";
        }
        margin-left: -15px;
        margin-right: -15px;
        p {
          //min-height: 80px;
          margin: 0 0;
          padding: 0 0;
        }
        //sm+ devices
        @media all and (min-width: $sm-min) {
          padding-bottom: 24px;
        }
        //xs devices
        @media all and (max-width: $xs-max) {
          & > div {
            margin-top: 5px;
          }
        }
      }
    }

    &[data-action='past'] {

    }
  }
  &[data-controller='ve_user_minimum_requirements'] {
    &[data-action='save'] {
      .fieldsetArea {
        height: auto;
      }
      .fieldsetArea:after,
      .form-group:after {
        clear: both;
        content: " ";
        //content:"asdf ";
      }
    }
  }
  &[data-controller='ve_user_results'] {
    &[data-action='view'] {
      svg#legend {
        g.past rect {
          fill: #008c8c;
        }
        g.future rect {
          fill: #66cfcf;
        }
      }
    }
  }
}

/*eo valuesExercise */

.veUserMinimumRequirements .save {
  select.ve_minimum_requirement_id {
    @media (max-width: $sm-min) {
      margin-bottom: 10px;
    }
  }
}

.veUserCriterias .prioritize {
  input.points {
    @media (max-width: $sm-min) {
      margin-bottom: 10px;
    }
  }
}

.veUserMinimumRequirementEvaluations .future {
  .min-req-table-header .col-3 h3 {
    text-align: center;
  }
  .min-req-table-body .col-3 {
    select,
    .selectize-control {
      width: 140px;
      margin: 0 auto;
    }
  }

}

.veUserResults.result td.rank {
  text-align: center;
}

.veUserResults.result td.score {
  text-align: center;
}

.veUserResults.result td.title {
}

.veUserResults.result td.company {
}

.veUserResults.result th.rank {
  text-align: center;
  width: 220px;
}

.veUserResults.result th.score {
  text-align: center;
}

.veUserResults.result th.title {
}

.veUserResults.result th.company {
}

.valuesExercise td.actions {
  min-width: 100px;
}

.actions.buttons {
  width: 400px;
  margin-left: 100px;
  padding-bottom: 20px;
}

//fix vertical alignment of buttons, this issue is due the icon height is more than font size
.veUserResults .report {
  .btn > {
    .glyphicon {
      top: 3px;
    }
  }
}

.progress-tool {
  height: 3px;
  //margin-bottom: 30px;
  overflow: visible;

  .progress-bar {
    position: relative;
    z-index: 4;
    height: 3px;

    span {
      display: block;
      z-index: 5;
      background-color: color(pv);
      //color: red;
      position: absolute;
      top: 0;
      right: -25px;
      //height: 50px;
      //width: 50px;
      border-radius: 3px;
      //left:-25px;
      padding: 8px 6px;
      font-size: $font-size-base * .75;
    }

  }

}

[type=reset], [type=submit],
button, html [type=button] {
  -webkit-appearance: none;
}


.c-divider {
  background: color(light-gray);
  width: 100%;
  height: 2px;
  clear: both;
  margin: 10px 0;
  padding: 0 0 0 0;
}