//data-controller='custom_nodes' data-action='home_pv'
body.node-slug-home {
  .main-content {
    section {
      .container {
        //padding-top: 64px;
        //padding-bottom: 64px;
      }
      &#benefits {
        //xs devices
        @media all and (max-width: $xs-max) {
          .boxes .box:last-child{padding-bottom: 0;}
        }
      }
      &#hero {
        .head {
          font-size: 100px;
          font-weight: 900;
          font-family: Lato-Black;
        }
        @media all and (max-width: $xs-max) {
          .head {
            font-size: 60px;
          }
        }

      }

    }
  }

}


