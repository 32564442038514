
[data-plugin='users'] {
  .popover-content {
    span {
      .glyphicon {
        font-size: 90%;
        margin-right: 10px;
        display: inline-block;
      }
      display: block;
      padding: 5px 20px;
    }
    .good {
      color: #3c763d;
    }
    .bad {
      color: #a94442;
    }
  }
}


[data-plugin="cart"][data-controller="products"][data-action="index"]{
  .panel .panel-collapse {
    border: 0 none;
    .panel-body{
      padding: 0 0;
    }
  }

  .shopping-cart .flash-message .container{
    width: auto;
  }
}

//.navbar-dark .navbar-nav .nav-link {
//  color: color(white);
//  &:hover{
//    color: color(white);
//  }
//}

[data-controller="toolboxes"]{

  //sm devices
  @media all and (min-width: $sm-min) {
    .btn {min-width: 250px;}
  }
}
