@font-face{
  font-family:'Lato-Light';
  font-style: normal;
  font-weight: 300;
  src: url($font-url + 'Lato-Light.ttf') format('trueType');
}
@font-face{
  font-family:'Lato-Light';
  font-style: italic;
  font-weight: 300;
  src: url($font-url + 'Lato-LightItalic.ttf') format('trueType');
}
@font-face{
  font-family:'Lato-Regular';
  font-style: normal;
  font-weight: 400;
  src: url($font-url + 'Lato-Regular.ttf') format('trueType');
}

@font-face{
  font-family:'Lato-Bold';
  font-style: normal;
  font-weight: 700;
  src: url($font-url + 'Lato-Bold.ttf') format('trueType');
}
@font-face{
  font-family:'Lato-Bold';
  font-style: italic;
  font-weight: 700;
  src: url($font-url + 'Lato-BoldItalic.ttf') format('trueType');
}

@font-face{
  font-family:'Lato-Black';
  font-style: normal;
  font-weight: 900;
  src: url($font-url + 'Lato-Black.ttf') format('trueType');
}
@font-face{
  font-family:'Lato-Black';
  font-style: italic;
  font-weight: 900;
  src: url($font-url + 'Lato-BlackItalic.ttf') format('trueType');
}