//xs+sm devices
@media all and (max-width: $sm-max) {
}

//xs devices
@media all and (max-width: $xs-max) {
  div[class*=" col-"] ~ div[class*=" col-"] {
    //margin-top: 20px;
  }

  div[class^="col-"] ~ div[class^="col-"] {
    //margin-top: 20px;
  }

}

//sm devices
@media all and (min-width: $sm-min) and (max-width: $sm-max) {
  .text-center-xs{text-align: center;}
}

//md devices
@media all and (min-width: $md-min) and (max-width: $md-max) {
}

//lg devices
@media all and (min-width: $lg-min) {
}

