//container has bootstrap controlled height
//reset to default
[data-plugin='cart'] {
  &[data-controller='products'] {
    &[data-action="index"] {
      .main-content {
        padding-top: 0px;
        section {
          //xs devices
          @media all and (max-width: $xs-max) {
            .container {
              padding-top: 32px;
              padding-bottom: 32px;
            }
          }
          &#hero {
            min-height: initial;
          }
        }
      }

    }
  }
}

.shopping-cart {
  #flash-message .container {
    width: initial;
  }
}

[data-plugin='cart'] {
  &[data-controller='products'] {
    &[data-controller='index'] {

    }
  }
}
