.panel {
    border-width: 3px;
    box-shadow: 0 1px 2px #eef1f3;
    margin-bottom: 22px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
}
.panel-info {
  border-color: #d8e2e4;
  .panel-heading{
    // color:color(pv)!important;
    padding:10px;
    background-image: linear-gradient(to bottom,#eef1f3 0,#dee5e9 100%);
    background-repeat: repeat-x;
    .tool-logo{
      color:color(pv);      
    }
  }
  .panel-body{
    padding:10px;
    color:color(charcoal)!important;
    .more{
      color:color(pv);
    }
    .client-name{
      padding:5px;
      color:color(pv);
      display:block;
    }
  }
}
