/**
using !important https://css-tricks.com/specifics-on-css-specificity/
 */
$i: 4;
@while $i < 65 {
  .margin-top-#{$i} {
    margin-top: #{$i}px!important;
  }
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}px!important;
  }
  .padding-top-#{$i} {
    padding-top: #{$i}px!important;
  }
  .padding-bottom-#{$i} {
    padding-bottom: #{$i}px!important;
  }
  $i: $i + 4;
}

//xs devices
@media all and (max-width: $xs-max) {
  $i: 4;
  @while $i < 65 {
    .margin-top-#{$i}-xs {
      margin-top: #{$i}px!important;
    }
    .margin-bottom-#{$i}-xs {
      margin-bottom: #{$i}px!important;
    }
    .padding-top-#{$i}-xs {
      padding-top: #{$i}px!important;
    }
    .padding-bottom-#{$i}-xs {
      padding-bottom: #{$i}px!important;
    }
    $i: $i + 4;
  }

}

//sm devices
@media all and (min-width: $sm-min) and (max-width: $sm-max) {
  $i: 4;
  @while $i < 65 {
    .margin-top-#{$i}-sm {
      margin-top: #{$i}px!important;
    }
    .margin-bottom-#{$i}-sm {
      margin-bottom: #{$i}px!important;
    }
    .padding-top-#{$i}-sm {
      padding-top: #{$i}px!important;
    }
    .padding-bottom-#{$i}-sm {
      padding-bottom: #{$i}px!important;
    }
    $i: $i + 4;
  }

}

//md devices
@media all and (min-width: $md-min) and (max-width: $md-max) {
  $i: 4;
  @while $i < 65 {
    .margin-top-#{$i}-md {
      margin-top: #{$i}px!important;
    }
    .margin-bottom-#{$i}-md {
      margin-bottom: #{$i}px!important;
    }
    .padding-top-#{$i}-md {
      padding-top: #{$i}px!important;
    }
    .padding-bottom-#{$i}-md {
      padding-bottom: #{$i}px!important;
    }
    $i: $i + 4;
  }

}

//lg devices
@media all and (min-width: $lg-min) {
  $i: 4;
  @while $i < 65 {
    .margin-top-#{$i}-lg {
      margin-top: #{$i}px!important;
    }
    .margin-bottom-#{$i}-lg {
      margin-bottom: #{$i}px!important;
    }
    .padding-top-#{$i}-lg {
      padding-top: #{$i}px!important;
    }
    .padding-bottom-#{$i}-lg {
      padding-bottom: #{$i}px!important;
    }
    $i: $i + 4;
  }

}