.content-area {
  .nodes,
  #node-content {
    .node-term-title {
      margin-bottom: 24px;
      .tag-archives {
        font-size: 20px;
      }
      .head{font-size:32px;}
    }

    .node + .node {
      margin-top: 32px;
    }

    .node-type-pv-blog {
      padding-bottom: 0px;
    }
    .node-title {
      font-size: 28px;
      padding-bottom: 6px;
      font-weight: 600;
    }
    .node-info {
      font-size: 14px;
      padding-bottom: 16px;
      i {
        font-size: 18px;
        padding-right: 5px;
      }
    }
    img {
      padding-top: 5px;
      padding-right: 5px;
      padding-bottom: 0px;
      padding-right: 0px;
      max-width: 100%;
    }
    .node-excerpt {
      font-size: 16px;
      padding: 0px;
      margin: 0px;
    }
    .continue-reading {
      font-size: 18px;
      padding-top: 10px;
      &:after {
        color: color(pv);
        padding-left: 8px;
        content: "\f0da";
        font-family: FontAwesome;
        display: inline-block;
        //animation: bounce-right 2s infinite;
      }

    }
    .node-more-info {
      padding-top: 10px;
      font-size: 16px;
      color: darken(color(gray), 20%);
    }

    .node-body {
      h1 {
        font-size: 20px;
        padding-bottom: 10px;
        margin: 0px;
      }
      p {
        font-size: 16px;
        padding-bottom: 10px;
        margin: 0px;
      }
    }

    .node-paging {
      margin-top: 24px;
      border-top: 1px solid #E6E6E6;
      .pagination {
        margin: 24px 0;
      }
    }
  }

}
