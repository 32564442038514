$i: 1;
@while $i < 10 {
  .lines-#{$i} {
    min-height: $font-size-base * $line-height-base * $i;
  }
  $i: $i + 1;
}

// text color
//@each $name, $hex in $colors {
//  .fg-color-#{$name} {
//    color: #{$hex};
//  }
//}

// bg color
//@each $name, $hex in $colors {
//  .bg-color-#{$name} {
//    background-color: #{$hex};
//  }
//}

p {
  margin: 0 0 20px 0;
  &:last-child {
    margin: 0 0 0 0;
  }
}

.note {
  font-size: 80%;
}

//br {
//  font-size: $font-size-base;
//  line-height: $font-size-base;
//  display: block;
//  margin: 10px 0;
//
//  &.quarter {
//    //font-size: ($font-size-base) / 4;
//    //line-height: ($font-size-base) / 4;
//    //min-height: ($font-size-base) / 4;
//    &:after{
//      height: ($font-size-base) / 4;
//    }
//
//  }
//  &.half {
//    //font-size: ($font-size-base) / 2;
//    //line-height: ($font-size-base) / 2;
//    //min-height: ($font-size-base) / 2;
//    height: ($font-size-base) / 2;
//  }
//  &.three-quarter {
//    //font-size: ($font-size-base) * 3 / 4;
//    //line-height: ($font-size-base) * 3 / 4;
//    //min-height: ($font-size-base) * 3 / 4;
//    height: ($font-size-base) * 3 / 4;
//  }
//}

//visible on sc hide on pv
.site-sc-visible {
  display: none;
}

//.site-pv-hidden{}

//.site-pv{}

.head {
  font-size: $font-size-base*3;
  padding-top: 0;
}

.subhead {
  font-size: $font-size-base*1.5;
  font-family: Lato-Regular;
  padding-top: 0;
  //padding-bottom: 16px;
  margin-bottom: 20px;
}

.bg-color-light-gray,
.bg-color-white {
  .subhead {
    //color: color(black);
  }
}

.main-content {
  section {
    .container {
      padding-top: 32px;
      padding-bottom: 32px;
      //xs devices
      @media all and (max-width: $xs-max) {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }

    &#hero {
      min-height: 316px;
      padding-top: 70px;
      //padding-top: 64px;
      background-color: color(pvd2);
      background-color: #018888;
      background-image: url($image-url + "home/hero-home-v1.png");
      //background-size: 1100px;
      //background-size: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      .head {
        font-size: 45px;
        font-weight: 400;
        font-family: Lato-Light;
      }
      h3 {
        padding-bottom: 16px;
      }

    }
    //xs devices

    &#flash-message {
      .container {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    &.shared-testimonials-slider,
    &.testimonials,
    &#testimonials {
      $lineHeight: 1.5;
      .container {
        padding-top: 0;
        padding-bottom: 0;
        #testimonials-slider {
          padding: 0 100px;
          .slick-track {
            transition: none;
          }
        }
        //xs devices
        @media all and (max-width: $xs-max) {
          #testimonials-slider {
            padding: 0 0;
            .slick-prev,
            .slick-next {
              display: none !important;
            }

          }
        }
        .client-name {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 12px;
          padding-bottom: 0;

        }

        blockquote {
          //font-size: $font-size-base;
          //min-height: $font-size-base*$lineHeight*3;
          & + .client-name {
            //margin-top: 8px+$font-size-base*.9*$lineHeight;
          }
        }
        .readmore-js {
          //If the element has a max-height CSS property, Readmore.js will use that value rather than the value of the collapsedHeight option.
          $lines: 3;
          max-height: (20px * $lineHeight * $lines); /*lines*/

          & + a[data-readmore-toggle] {
            line-height: $lineHeight !important;
            text-align: center !important;
            font-size: $font-size-base*.9;
            color: color(light-gray);
          }
        }
        .slick-slider {
          margin-bottom: 0;
        }
        .slick-slide {

          padding-top: 42px;
          padding-bottom: 62px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .slick-slide {
        //min-height: 500px;
        //display: flex;
      }
      .slick-dots {
        bottom: 20px;
        left: 0;
        right: 0;
        li button::before {
          font-size: 26px;
          color: color(charcoal);
        }
      }
    }
    &#hero {
      $lineHeight: 1.5;
      .container {
        //padding-top: 0;
        //padding-bottom: 0;
        #testimonials-slider {
          padding: 0 100px;
          .slick-track {
            transition: none;
          }
        }
        //xs devices
        @media all and (max-width: $xs-max) {
          #testimonials-slider {
            padding: 0 0;
            .slick-prev,
            .slick-next {
              display: none !important;
            }
          }
        }
        .client-name {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 12px;
          padding-bottom: 0;

        }

        blockquote {
          //font-size: $font-size-base;
          //min-height: $font-size-base*$lineHeight*3;
          & + .client-name {
            //margin-top: 8px+$font-size-base*.9*$lineHeight;
          }
        }
        .slick-slider {
          margin-bottom: 0;
        }
        .slick-slide {
          padding-top: 42px;
          padding-bottom: 62px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .slick-slide {
        //min-height: 500px;
        //display: flex;
      }
      .slick-dots {
        bottom: 20px;
        left: 0;
        right: 0;
        li button::before {
          font-size: 26px;
          color: color(charcoal);
        }
      }
    }
    &.the-team
    {
      /**
    * The cards
    *
    * Each card plays home to a front and back. I've set them up as squares here
    * by using the padding technique, but you may set them up in any dimensions
    * you like based on your site design.
    */
      .card {
        position: relative;
      }

      .card:nth-child(1) {
        /* margin-left: -3px; */
        /* margin-right: 1px; */
      }

      .card:nth-child(2),
      .card:nth-child(3) {
        margin-right: 1px;
      }

      /* card fronts and backs */
      .card__front,
      .card__back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 25px;
      }

      .card__front,
      .card__back {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
      }

      .card__front {
        //background-color: #ff5078;
      }

      .card__back {
        font-size: 90%;
        @media all and (max-width: $xs-max) {
          font-size: 80%;
        }
        background-color: color(white);
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
      }

      /* card text */
      .card__textz {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 20px;
        color: #fff;
        font-family: "Roboto Slab", serif;
        line-height: 20px;
      }

      /* hover effect */
      .card.effect__hover:hover .card__front {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
      }

      .card.effect__hover:hover .card__back {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }

      /* click effect */
      .card.effect__click.flipped .card__front {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
      }

      .card.effect__click.flipped .card__back {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }

      /* random effect */
      .card.effect__random.flipped .card__front {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
      }

      .card.effect__random.flipped .card__back {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }

      $boxHeight: 350px;
      min-height: $boxHeight + 32px * 2;
      .box {
        min-height: $boxHeight;
        text-align: center;
        background-color: lighten(color(gray), 20%);
        border: 1px solid color(gray);
        h4 {
          text-transform: uppercase;
          margin-top: 25px;
        }
        .divider {
          margin: 0 auto;
          width: 58px;
          border-bottom: 1px solid color(dark-gray);
          padding-top: 16px;
        }
        span {
          display: block;
          padding-top: 24px;
          color: color(dark-gray);
        }
        .card__front {
          padding: 25px;
          //text-align: left;
        }
        .card__back {

          //text-align: left;
        }
      }

      //xs devices
      @media all and (max-width: $xs-max) {
        .box {
          margin-bottom: 25px;
        }
      }
    }
    &#cta {
      .subhead {
        padding-bottom: 0;
      }
    }
    &#faq {
      ul {
        list-style: none;
        margin: 0 0;
        padding: 0 0;
        li {
          position: relative;
          margin: 0 0;
          padding-left: 24px;
          padding-top: $font-size-base/2;
          padding-bottom: $font-size-base/2;
          &.current {
            border-left: 2px solid #1db3e9;
            padding: 0 0 0 13px;
          }
          &:before {
            position: absolute;
            left: 0;
            left: 0;
            //width:30px;
            font-family: FontAwesome;
            font-size: $font-size-base;
            content: '\f0da';
            display: inline-block;
          }
        }
      }
    }
    &.ten-reasons,
    &#ten-reasons {
      ul {
        list-style: none;
        margin: 0 0;
        padding: 0 0;
        li {
          position: relative;
          margin: 0 0;
          padding-left: 44px;
          padding-top: $font-size-base/2;
          padding-bottom: $font-size-base/2;
          &.current {
            border-left: 2px solid #1db3e9;
            padding: 0 0 0 13px;
          }
          &:before {
            position: absolute;
            left: 0;
            //padding + line-height/2
            top: $font-size-base/2 + $font-size-base*.2;
            //width:30px;
            //font-family :FontAwesome;
            //background-image: ;
            background: url($image-url + "shared/checkbox.png") no-repeat;
            background-size: contain;
            content: "";
            width: 24px;
            height: 21px;
            display: inline-block;
          }
        }
      }
    }

  }

}

.img-parent {
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.boxes {
  @include boxes();
  .box {
    @include box();
  }
}

[data-controller="custom_nodes"][data-action="home_pv"],
[data-controller="custom_nodes"][data-action="view"] {

  section {
    &#hero {
      min-height: 616px;
      padding-top: 150px;
      padding-bottom: 60px;
      background-color: color(pvd2);
      background-color: #018888;
      //background-image: url($image-url + "home/hero-home-v1.png");
      //background-size: 1100px;
      //background-size: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top right;
      .head {
        font-size: 100px;
        font-weight: 900;
        font-family: Lato-Black;
      }
      h3 {
        padding-bottom: 16px;
      }
    }
    &#benefits {
      .parent-img {
        //svg{width:100px;}
      }
      .divider {
        margin: 0 auto;
        width: 100%;
        border-bottom: 5px solid lighten(color(dark-gray), 5%);
        //border-bottom: 6px solid #5E5F5F;
        padding-top: 16px;
      }
      .subhead {
        font-family: Lato-Black;
        padding-top: 16px;
      }

    }

  }
}

[data-controller="custom_nodes"] {
  &[data-action="search"],
  &[data-action="search_pv"] {
  }
}

[data-controller="nodes"][data-action="view"] {
  //.container {
  //  max-width: 1000px;
  //}
  section {
  }
}

.pagination {
  ul {
    -webkit-padding-start: 0px;
  }
  .btn {
    margin: 4px;
    min-width: auto;
    a {
      &,
      &:active,
      &:hover,
      &:focus,
      &:visited {
        color: inherit;
        text-decoration: none;
      }
      display: inline-block;
      &.current.btn-pv {
        background-color: color(white);
        background-color: color(pv);
      }
    }

    &.current.btn-pv {
      //this has no a tag in it
      background-color: color(light-gray);
      color: color(pv);
    }

  }
}

.actions {
  .btn-action {
    padding: 4px;
  }
}

//css-image-caching
//https://perishablepress.com/css-image-caching/
//<div id="preloader">
//  <img src="http://domain.tld/path/images/01.png" width="1" height="1" />
//  <img src="http://domain.tld/path/images/07.png" width="1" height="1" />
//</div>
@media screen {
  div#preloader {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  div#preloader img {
    display: block;
  }
}

@media print {
  div#preloader,
  div#preloader img {
    visibility: hidden;
    display: none;
  }
}

//end of
//css-image-caching

form .form-group .box {
  min-height: 26px;
}

[data-plugin='contacts'][data-controller='contacts'][data-action='contact'] {
  section#node-content {
    .node-title {
      margin-bottom: 20px;
    }
    .node-body {
      margin-bottom: 20px;
    }
  }
}

.responsive-img-wrapper {
  width: 100%;
  position: relative;
  left: 0;
  right: initial;
  &.align-right {
    left: initial;
    right: 0 !important;
    float: right;
  }
  > img {
    max-width: 100%; /*this style is required untill we remove .responsive class from body and make it's style global*/
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // width to be set by responsive_image function
  }
}

.list-style-1 {
  //list-style: none;
  list-style-position: outside;
  margin: 0 0;
  padding: 0 20px;
  li {
    position: relative;
    margin: 0 0;
    padding-left: 15px;
    padding-top: $font-size-base/4;
    padding-bottom: $font-size-base/4;
    &:before {
      padding-left: 10px;
    }
  }
}

.btn-primary:disabled,
disabled {
  &:hover {
    color: color(white) !important;
  }
}

.btn {
  min-width: 200px;
}

[class*=bg-color-] {
  a:not(.btn) {
    color: inherit;
  }
}
