.readmore-js {
  $lines: 3;
  $lineHeight: 1.5;
  //update the same in app.js
  position: relative;
  &:before, &:after {
    content: " ";
    width: 50px;
    height: 50px;
    display: block;
    background-size: 50px 50px;
    position: absolute;
    background-repeat: no-repeat;
  }
  //blockquote,
  //padding: 30px 50px;
  font-size: $font-size-base;
  line-height: $lineHeight;
  //If the element has a max-height CSS property, Readmore.js will use that value rather than the value of the collapsedHeight option.
  max-height: 20px * $lineHeight * $lines; /*lines*/

  & + a[data-readmore-toggle] {
    text-decoration: none;
    display: block;
    text-align: right !important;
    padding: 8px 0 0 0;
  }
}