.slick-pv-layout {
  padding: 0 100px;
  .slick-track {
    transition: none;
  }

  //xs devices
  @media all and (max-width: $xs-max) {
    padding: 0 40px;
  }

  .slick-slider {
    margin-bottom: 0;
  }
  .slick-slide {
    padding-top: 42px;
    padding-bottom: 62px;
    padding-left: 0px;
    padding-right: 0px;
  }

}

.slick-slider {
  $z-index: 500;
  z-index: $z-index;
  $position: 0;
  .slick-prev, .slick-next {
    z-index: $z-index+1;
    width: 40px;
  }
  .slick-prev {
    left: $position;
  }
  .slick-next {
    right: $position;
  }
  .slick-prev:before, .slick-next:before {
    font-family: FontAwesome;
    font-size: $font-size-base * 2;
    line-height: 1;
    color: color(white);
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev:before {
    content: "\f053";
  }
  [dir="rtl"] .slick-prev:before {
    content: "\f054";
  }

  [dir="rtl"] .slick-next {
    left: -10px;
    top: 70px;
    right: auto;
  }
  .slick-next:before {
    content: "\f054";
  }
  [dir="rtl"] .slick-next:before {
    content: "\f053";
  }

}