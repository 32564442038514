.fg-color-pv {
  color: #00b0b0;
}

.fg-color-warning {
  color: #ccbc2c;
}

.fg-color-primary {
  color: #00b0b0;
}

.fg-color-white {
  color: #fff;
}

.fg-color-info {
  color: #2c57cc;
}

.fg-color-danger {
  color: #700914;
}

.fg-color-green {
  color: #00b0b0;
}

.fg-color-charcoal {
  color: #4c4f54;
}

.fg-color-error {
  color: #700914;
}

.fg-color-gray {
  color: #C2C2C2;
}

.fg-color-dark-gray {
  color: #5E5E5E;
}

.fg-color-light-gray {
  color: #F3F3F3;
}

.fg-color-success {
  color: #097011;
}

.fg-color-black {
  color: #000000;
}

.fg-color-pvd1 {
  color: #009e9e;
}

.fg-color-pvd2 {
  color: #008c8c;
}

.fg-color-pvd3 {
  color: #007b7b;
}

.fg-color-pvd4 {
  color: #006969;
}

.fg-color-pvd5 {
  color: #005858;
}

.fg-color-pvd6 {
  color: #004646;
}

.fg-color-pvd7 {
  color: #003434;
}

.fg-color-pvd8 {
  color: #002323;
}

.fg-color-pvd9 {
  color: #001111;
}

.fg-color-pvl1 {
  color: #19b7b7;
}

.fg-color-pvl2 {
  color: #32bfbf;
}

.fg-color-pvl3 {
  color: #4cc7c7;
}

.fg-color-pvl4 {
  color: #66cfcf;
}

.fg-color-pvl5 {
  color: #7fd7d7;
}

.fg-color-pvl6 {
  color: #99dfdf;
}

.fg-color-pvl7 {
  color: #b2e7e7;
}

.fg-color-pvl8 {
  color: #ccefef;
}

.fg-color-pvl9 {
  color: #e5f7f7;
}

.fg-color-pvl10 {
  color: #edffff;
}

.bg-color-pv {
  background-color: #00b0b0;
}

.bg-color-warning {
  background-color: #ccbc2c;
}

.bg-color-primary {
  background-color: #00b0b0;
}

.bg-color-white {
  background-color: #fff;
}

.bg-color-info {
  background-color: #2c57cc;
}

.bg-color-danger {
  background-color: #700914;
}

.bg-color-green {
  background-color: #00b0b0;
}

.bg-color-charcoal {
  background-color: #4c4f54;
}

.bg-color-error {
  background-color: #700914;
}

.bg-color-gray {
  background-color: #C2C2C2;
}

.bg-color-dark-gray {
  background-color: #5E5E5E;
}

.bg-color-light-gray {
  background-color: #F3F3F3;
}

.bg-color-success {
  background-color: #097011;
}

.bg-color-black {
  background-color: #000000;
}

.bg-color-pvd1 {
  background-color: #009e9e;
}

.bg-color-pvd2 {
  background-color: #008c8c;
}

.bg-color-pvd3 {
  background-color: #007b7b;
}

.bg-color-pvd4 {
  background-color: #006969;
}

.bg-color-pvd5 {
  background-color: #005858;
}

.bg-color-pvd6 {
  background-color: #004646;
}

.bg-color-pvd7 {
  background-color: #003434;
}

.bg-color-pvd8 {
  background-color: #002323;
}

.bg-color-pvd9 {
  background-color: #001111;
}

.bg-color-pvl1 {
  background-color: #19b7b7;
}

.bg-color-pvl2 {
  background-color: #32bfbf;
}

.bg-color-pvl3 {
  background-color: #4cc7c7;
}

.bg-color-pvl4 {
  background-color: #66cfcf;
}

.bg-color-pvl5 {
  background-color: #7fd7d7;
}

.bg-color-pvl6 {
  background-color: #99dfdf;
}

.bg-color-pvl7 {
  background-color: #b2e7e7;
}

.bg-color-pvl8 {
  background-color: #ccefef;
}

.bg-color-pvl9 {
  background-color: #e5f7f7;
}

.bg-color-pvl10 {
  background-color: #edffff;
}

@font-face {
  font-family: 'Lato-Light';
  font-style: normal;
  font-weight: 300;
  src: url("/theme/ProValues/fonts/Lato-Light.ttf") format("trueType");
}

@font-face {
  font-family: 'Lato-Light';
  font-style: italic;
  font-weight: 300;
  src: url("/theme/ProValues/fonts/Lato-LightItalic.ttf") format("trueType");
}

@font-face {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: 400;
  src: url("/theme/ProValues/fonts/Lato-Regular.ttf") format("trueType");
}

@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: 700;
  src: url("/theme/ProValues/fonts/Lato-Bold.ttf") format("trueType");
}

@font-face {
  font-family: 'Lato-Bold';
  font-style: italic;
  font-weight: 700;
  src: url("/theme/ProValues/fonts/Lato-BoldItalic.ttf") format("trueType");
}

@font-face {
  font-family: 'Lato-Black';
  font-style: normal;
  font-weight: 900;
  src: url("/theme/ProValues/fonts/Lato-Black.ttf") format("trueType");
}

@font-face {
  font-family: 'Lato-Black';
  font-style: italic;
  font-weight: 900;
  src: url("/theme/ProValues/fonts/Lato-BlackItalic.ttf") format("trueType");
}

.form-horizontal .control-label {
  margin: 0px;
}

.control-label {
  font-size: 1.0625rem;
}

.form-control {
  font-size: 1.25rem;
}

.breadcrumb {
  margin-bottom: 0;
}

.alert {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 3px solid #00b0b0;
  font-weight: normal;
  font-family: Lato-Regular;
}

.alert .glyphicon {
  margin-right: 15px;
}

.alert .glyphicon {
  top: 3px;
}

.alert-success {
  background-color: #fff;
  border-color: #f3f3f3;
  color: #097011;
}

.alert-info {
  background-color: #fff;
  border-color: #f3f3f3;
  color: #2c57cc;
}

.alert-warning {
  background-color: #fff;
  border-color: #f3f3f3;
  color: #ccbc2c;
}

.alert-danger {
  background-color: #fff;
  border-color: #f3f3f3;
  color: #700914;
}

.panel-group .panel {
  margin-bottom: 30px;
  border-radius: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 0 none;
}

.panel {
  box-shadow: none;
  border: none;
  font-family: Lato-Regular;
  border-radius: 0;
  background: white;
  box-shadow: none;
  margin-top: 0 !important;
}

.panel a, .panel a:visited {
  outline: none;
  text-decoration: none;
  user-select: none;
}

.panel .panel-heading {
  background: white;
  padding: 0 0;
  border-color: transparent;
  border: none;
}

.panel .panel-heading .panel-title {
  padding: 0 0;
  font-family: Lato-Regular;
  font-size: 1.40625rem;
  position: relative;
}

.panel .panel-heading .panel-title > a {
  display: block;
  color: #4c4f54;
  border-left: 2px solid #C2C2C2;
  border-right: 2px solid #C2C2C2;
  border-top: 2px solid #C2C2C2;
  border-bottom: 2px solid #C2C2C2;
  padding: 24px 70px 24px 24px;
  width: 100%;
}

.panel .panel-heading .panel-title > a[aria-expanded="true"] {
  border-left: 2px solid #00b0b0;
  border-right: 2px solid #00b0b0;
  border-top: 2px solid #00b0b0;
  border-bottom: none;
}

.panel .panel-heading .panel-title > a[aria-expanded="true"]:before {
  content: "\f068";
}

@media all and (max-width: 767px) {
  .panel .panel-heading .panel-title > a {
    padding: 16px 70px 16px 16px;
  }
}

.panel .panel-heading .panel-title > a:before {
  font-family: FontAwesome;
  font-weight: normal;
  background-size: 28px;
  height: 28px;
  width: 28px;
  content: "\f067";
  position: absolute;
  top: 27px;
  right: 30px;
}

@media all and (max-width: 767px) {
  .panel .panel-heading .panel-title > a:before {
    top: 19px;
  }
}

.panel .panel-collapse {
  border-left: 2px solid #C2C2C2;
  border-right: 2px solid #C2C2C2;
  border-bottom: 2px solid #C2C2C2;
}

.panel .panel-collapse.collapsing {
  color: white;
  transition: all 0.1s ease;
}

.panel .panel-collapse.collapse.in {
  border-left: 2px solid #00b0b0;
  border-right: 2px solid #00b0b0;
  border-bottom: 2px solid #00b0b0;
  border-top: none;
}

.panel .panel-collapse > .panel-body {
  padding: 0 24px 24px 24px;
}

.panel .panel-collapse a {
  margin-bottom: 5px;
}

.panel .list-group-item {
  border-radius: 0;
  position: relative;
  display: block;
  padding: 10px 15px;
  padding: 10px 0;
  margin-bottom: -1px;
  border: none;
}

.panel a.list-group-item, .panel a.list-group-item:focus, .panel a.list-group-item:visited, .panel a.list-group-item:hover, .panel button.list-group-item, .panel button.list-group-item:focus, .panel button.list-group-item:visited, .panel button.list-group-item:hover {
  color: #00b0b0;
  background-color: initial;
}

.panel .list-group-item:first-child {
  border-radius: 0;
}

.panel.panel-pv .panel-heading {
  color: #4c4f54;
}

.panel.panel-pv .panel-heading .panel-title > a {
  border-color: #00b0b0;
}

.panel.panel-pv .panel-heading .panel-title > a:before {
  color: #00b0b0;
}

.panel.panel-pv .panel-heading .panel-title > a[aria-expanded="true"] {
  border-color: #00b0b0;
}

.panel.panel-pv .panel-heading + > .panel-collapse > .panel-body {
  border-top-color: transparent;
  border-top: 0 none;
}

.panel.panel-info .panel-heading {
  color: #4c4f54;
}

.panel.panel-info .panel-heading .panel-title > a {
  border-color: #00b0b0;
}

.panel.panel-info .panel-heading .panel-title > a:before {
  color: #00b0b0;
}

.panel.panel-info .panel-heading .panel-title > a[aria-expanded="true"] {
  border-color: #00b0b0;
}

.panel.panel-info .panel-heading + > .panel-collapse > .panel-body {
  border-top-color: transparent;
  border-top: 0 none;
}

.panel.panel-default .panel-heading {
  color: #4c4f54;
}

.panel.panel-default .panel-heading .panel-title > a {
  border-color: #C2C2C2;
}

.panel.panel-default .panel-heading .panel-title > a:before {
  color: #C2C2C2;
}

.panel.panel-default .panel-heading .panel-title > a[aria-expanded="true"] {
  border-color: #C2C2C2;
}

.panel.panel-default .panel-heading + > .panel-collapse > .panel-body {
  border-top-color: transparent;
  border-top: 0 none;
}

.panel.panel-default .panel-heading {
  background-color: #F3F3F3;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
  left: 0;
  top: 13px;
  margin-left: 0px;
  margin-top: 4px;
  margin-right: 5px;
}

.readmore-js {
  position: relative;
  font-size: 1.25rem;
  line-height: 1.5;
  max-height: 90px;
  /*lines*/
}

.readmore-js:before, .readmore-js:after {
  content: " ";
  width: 50px;
  height: 50px;
  display: block;
  background-size: 50px 50px;
  position: absolute;
  background-repeat: no-repeat;
}

.readmore-js + a[data-readmore-toggle] {
  text-decoration: none;
  display: block;
  text-align: right !important;
  padding: 8px 0 0 0;
}

.slick-pv-layout {
  padding: 0 100px;
}

.slick-pv-layout .slick-track {
  transition: none;
}

@media all and (max-width: 767px) {
  .slick-pv-layout {
    padding: 0 40px;
  }
}

.slick-pv-layout .slick-slider {
  margin-bottom: 0;
}

.slick-pv-layout .slick-slide {
  padding-top: 42px;
  padding-bottom: 62px;
  padding-left: 0px;
  padding-right: 0px;
}

.slick-slider {
  z-index: 500;
}

.slick-slider .slick-prev, .slick-slider .slick-next {
  z-index: 501;
  width: 40px;
}

.slick-slider .slick-prev {
  left: 0;
}

.slick-slider .slick-next {
  right: 0;
}

.slick-slider .slick-prev:before, .slick-slider .slick-next:before {
  font-family: FontAwesome;
  font-size: 2.5rem;
  line-height: 1;
  color: #fff;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider .slick-prev:before {
  content: "\f053";
}

.slick-slider [dir="rtl"] .slick-prev:before {
  content: "\f054";
}

.slick-slider [dir="rtl"] .slick-next {
  left: -10px;
  top: 70px;
  right: auto;
}

.slick-slider .slick-next:before {
  content: "\f054";
}

.slick-slider [dir="rtl"] .slick-next:before {
  content: "\f053";
}

/*bower_components/components-font-awesome*/
.rating-xl {
  font-size: 4.89rem;
}

.rating-lg {
  font-size: 3.91rem;
}

.rating-md {
  font-size: 3.13rem;
}

.rating-sm {
  font-size: 2.5rem;
}

.rating-xs {
  font-size: 1.3rem;
}

.filled-stars {
  -webkit-text-stroke: 1px #007b7b !important;
  text-shadow: none !important;
  color: #00b0b0 !important;
}

.fa-star,
.fa-star-o,
.empty-stars {
  color: #00b0b0 !important;
}

.rating-animate .filled-stars {
  transition: none !important;
}

* {
  outline: none !important;
}

body {
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1.25rem;
  line-height: 1.2;
  margin: 0;
  font-weight: normal;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5625rem;
}

h4 {
  font-size: 1.40625rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.25rem;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {
  padding-bottom: 0;
}

blockquote {
  padding: 0;
  margin: 0;
  border: none;
}

strong, .strong {
  font-family: Lato-Regular;
  font-weight: 400;
}

a:no t(.btn), a:not(.btn):visited, a:not(.btn):hover {
  color: #00b0b0;
}

.bg-color-pv a:no t(.btn), .bg-color-pv a:not(.btn):visited, .bg-color-pv a:not(.btn):hover {
  color: #fff;
}

/*fonts test cases*/
#fonts-test {
  font-size: 1.875rem;
}

.Lato-Black {
  font-family: Lato-Black, "Comic Sans MS";
}

.Lato-Bold {
  font-family: Lato-Bold, "Comic Sans MS";
}

.Lato-Regular {
  font-family: Lato-Regular, "Comic Sans MS";
}

.Lato-Light {
  font-family: Lato-Light, "Comic Sans MS";
}

@keyframes bounce-right {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(5px);
  }
}

/*Forms
---------------------------------------*/
input[type="submit"] {
  line-height: 1.42857;
  font-size: 1.25rem;
}

p.error-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 1px;
  color: #700914;
  font-style: italic;
}

.info-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 1px;
  color: #2c57cc;
  font-style: italic;
}

.form-group.required label:after {
  color: #EE3322;
  content: "*";
  display: inline;
}

.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #700914;
}

.has-error .radio input[type="radio"] {
  clear: left;
}

.icon-help {
  /*cake form field error style*/
  background: url("../images/help.gif") no-repeat 0 0;
  width: 14px;
  height: 14px;
}

.icon-help:hover {
  background: url("../images/help_h.gif") no-repeat 0 0;
}

.info-block {
  /*cake form field error style*/
  font-size: 1.125rem;
  background: url("../images/info.gif") no-repeat 0 3px;
  padding-left: 21px;
  font-style: italic;
}

p.error-block {
  /*cake form field error style*/
  font-size: 1.125rem;
  background: url("../images/unchecked.gif") no-repeat 0 3px;
  padding-left: 21px;
  font-style: italic;
  color: #CB504A;
}

p.error-block.checked {
  background: url("../images/checked.gif") no-repeat 0 3px;
}

p.checked {
  background: url("../images/checked.gif") no-repeat 0 3px;
}

.box {
  /*width:300px;*/
  /*min-height: 40px;*/
  /*force php errors to move in new line*/
}

.c-inp-incl-msg {
  min-height: 73px;
}

label {
  font-weight: normal;
  color: #5E5E5E;
  font-family: Lato-Light;
}

.has-feedback label ~ .form-control-feedback {
  top: 40px;
}

.selectize-control.single .selectize-input,
.form-control {
  color: #4c4f54;
  font-family: Lato-Light;
  -webkit-appearance: none;
}

.selectize-control.multi .selectize-input {
  height: auto;
}

.selectize-control.multi .selectize-input > div {
  background-color: #19b7b7;
  color: #fff;
  padding: 5px 20px;
}

.selectize-input.input-active, .selectize-input.focus {
  box-shadow: none;
  border-color: #00b0b0;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: opacity 0.5s 0.5s ease;
  opacity: 0;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-28 {
  margin-bottom: 28px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.overflow-hidden {
  overflow: hidden;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .text-center-xs {
    text-align: center;
  }
}

.lines-1 {
  min-height: 1.78571rem;
}

.lines-2 {
  min-height: 3.57143rem;
}

.lines-3 {
  min-height: 5.35714rem;
}

.lines-4 {
  min-height: 7.14286rem;
}

.lines-5 {
  min-height: 8.92857rem;
}

.lines-6 {
  min-height: 10.71429rem;
}

.lines-7 {
  min-height: 12.5rem;
}

.lines-8 {
  min-height: 14.28571rem;
}

.lines-9 {
  min-height: 16.07143rem;
}

p {
  margin: 0 0 20px 0;
}

p:last-child {
  margin: 0 0 0 0;
}

.note {
  font-size: 80%;
}

.site-sc-visible {
  display: none;
}

.head {
  font-size: 3.75rem;
  padding-top: 0;
}

.subhead {
  font-size: 1.875rem;
  font-family: Lato-Regular;
  padding-top: 0;
  margin-bottom: 20px;
}

.main-content section .container {
  padding-top: 32px;
  padding-bottom: 32px;
}

@media all and (max-width: 767px) {
  .main-content section .container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.main-content section#hero {
  min-height: 316px;
  padding-top: 70px;
  background-color: #008c8c;
  background-color: #018888;
  background-image: url("/frontend/images/home/hero-home-v1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.main-content section#hero .head {
  font-size: 45px;
  font-weight: 400;
  font-family: Lato-Light;
}

.main-content section#hero h3 {
  padding-bottom: 16px;
}

.main-content section#flash-message .container {
  padding-top: 0;
  padding-bottom: 0;
}

.main-content section.shared-testimonials-slider .container, .main-content section.testimonials .container, .main-content section#testimonials .container {
  padding-top: 0;
  padding-bottom: 0;
}

.main-content section.shared-testimonials-slider .container #testimonials-slider, .main-content section.testimonials .container #testimonials-slider, .main-content section#testimonials .container #testimonials-slider {
  padding: 0 100px;
}

.main-content section.shared-testimonials-slider .container #testimonials-slider .slick-track, .main-content section.testimonials .container #testimonials-slider .slick-track, .main-content section#testimonials .container #testimonials-slider .slick-track {
  transition: none;
}

@media all and (max-width: 767px) {
  .main-content section.shared-testimonials-slider .container #testimonials-slider, .main-content section.testimonials .container #testimonials-slider, .main-content section#testimonials .container #testimonials-slider {
    padding: 0 0;
  }
  .main-content section.shared-testimonials-slider .container #testimonials-slider .slick-prev,
  .main-content section.shared-testimonials-slider .container #testimonials-slider .slick-next, .main-content section.testimonials .container #testimonials-slider .slick-prev,
  .main-content section.testimonials .container #testimonials-slider .slick-next, .main-content section#testimonials .container #testimonials-slider .slick-prev,
  .main-content section#testimonials .container #testimonials-slider .slick-next {
    display: none !important;
  }
}

.main-content section.shared-testimonials-slider .container .client-name, .main-content section.testimonials .container .client-name, .main-content section#testimonials .container .client-name {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 12px;
  padding-bottom: 0;
}

.main-content section.shared-testimonials-slider .container .readmore-js, .main-content section.testimonials .container .readmore-js, .main-content section#testimonials .container .readmore-js {
  max-height: 90px;
  /*lines*/
}

.main-content section.shared-testimonials-slider .container .readmore-js + a[data-readmore-toggle], .main-content section.testimonials .container .readmore-js + a[data-readmore-toggle], .main-content section#testimonials .container .readmore-js + a[data-readmore-toggle] {
  line-height: 1.5 !important;
  text-align: center !important;
  font-size: 1.125rem;
  color: #F3F3F3;
}

.main-content section.shared-testimonials-slider .container .slick-slider, .main-content section.testimonials .container .slick-slider, .main-content section#testimonials .container .slick-slider {
  margin-bottom: 0;
}

.main-content section.shared-testimonials-slider .container .slick-slide, .main-content section.testimonials .container .slick-slide, .main-content section#testimonials .container .slick-slide {
  padding-top: 42px;
  padding-bottom: 62px;
  padding-left: 0px;
  padding-right: 0px;
}

.main-content section.shared-testimonials-slider .slick-dots, .main-content section.testimonials .slick-dots, .main-content section#testimonials .slick-dots {
  bottom: 20px;
  left: 0;
  right: 0;
}

.main-content section.shared-testimonials-slider .slick-dots li button::before, .main-content section.testimonials .slick-dots li button::before, .main-content section#testimonials .slick-dots li button::before {
  font-size: 26px;
  color: #4c4f54;
}

.main-content section#hero .container #testimonials-slider {
  padding: 0 100px;
}

.main-content section#hero .container #testimonials-slider .slick-track {
  transition: none;
}

@media all and (max-width: 767px) {
  .main-content section#hero .container #testimonials-slider {
    padding: 0 0;
  }
  .main-content section#hero .container #testimonials-slider .slick-prev,
  .main-content section#hero .container #testimonials-slider .slick-next {
    display: none !important;
  }
}

.main-content section#hero .container .client-name {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 12px;
  padding-bottom: 0;
}

.main-content section#hero .container .slick-slider {
  margin-bottom: 0;
}

.main-content section#hero .container .slick-slide {
  padding-top: 42px;
  padding-bottom: 62px;
  padding-left: 0px;
  padding-right: 0px;
}

.main-content section#hero .slick-dots {
  bottom: 20px;
  left: 0;
  right: 0;
}

.main-content section#hero .slick-dots li button::before {
  font-size: 26px;
  color: #4c4f54;
}

.main-content section.the-team {
  /**
    * The cards
    *
    * Each card plays home to a front and back. I've set them up as squares here
    * by using the padding technique, but you may set them up in any dimensions
    * you like based on your site design.
    */
  /* card fronts and backs */
  /* card text */
  /* hover effect */
  /* click effect */
  /* random effect */
  min-height: 414px;
}

.main-content section.the-team .card {
  position: relative;
}

.main-content section.the-team .card:nth-child(1) {
  /* margin-left: -3px; */
  /* margin-right: 1px; */
}

.main-content section.the-team .card:nth-child(2),
.main-content section.the-team .card:nth-child(3) {
  margin-right: 1px;
}

.main-content section.the-team .card__front,
.main-content section.the-team .card__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
}

.main-content section.the-team .card__front,
.main-content section.the-team .card__back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.main-content section.the-team .card__back {
  font-size: 90%;
  background-color: #fff;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

@media all and (max-width: 767px) {
  .main-content section.the-team .card__back {
    font-size: 80%;
  }
}

.main-content section.the-team .card__textz {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 20px;
  color: #fff;
  font-family: "Roboto Slab", serif;
  line-height: 20px;
}

.main-content section.the-team .card.effect__hover:hover .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.main-content section.the-team .card.effect__hover:hover .card__back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.main-content section.the-team .card.effect__click.flipped .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.main-content section.the-team .card.effect__click.flipped .card__back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.main-content section.the-team .card.effect__random.flipped .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.main-content section.the-team .card.effect__random.flipped .card__back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.main-content section.the-team .box {
  min-height: 350px;
  text-align: center;
  background-color: whitesmoke;
  border: 1px solid #C2C2C2;
}

.main-content section.the-team .box h4 {
  text-transform: uppercase;
  margin-top: 25px;
}

.main-content section.the-team .box .divider {
  margin: 0 auto;
  width: 58px;
  border-bottom: 1px solid #5E5E5E;
  padding-top: 16px;
}

.main-content section.the-team .box span {
  display: block;
  padding-top: 24px;
  color: #5E5E5E;
}

.main-content section.the-team .box .card__front {
  padding: 25px;
}

@media all and (max-width: 767px) {
  .main-content section.the-team .box {
    margin-bottom: 25px;
  }
}

.main-content section#cta .subhead {
  padding-bottom: 0;
}

.main-content section#faq ul {
  list-style: none;
  margin: 0 0;
  padding: 0 0;
}

.main-content section#faq ul li {
  position: relative;
  margin: 0 0;
  padding-left: 24px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.main-content section#faq ul li.current {
  border-left: 2px solid #1db3e9;
  padding: 0 0 0 13px;
}

.main-content section#faq ul li:before {
  position: absolute;
  left: 0;
  left: 0;
  font-family: FontAwesome;
  font-size: 1.25rem;
  content: '\f0da';
  display: inline-block;
}

.main-content section.ten-reasons ul, .main-content section#ten-reasons ul {
  list-style: none;
  margin: 0 0;
  padding: 0 0;
}

.main-content section.ten-reasons ul li, .main-content section#ten-reasons ul li {
  position: relative;
  margin: 0 0;
  padding-left: 44px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.main-content section.ten-reasons ul li.current, .main-content section#ten-reasons ul li.current {
  border-left: 2px solid #1db3e9;
  padding: 0 0 0 13px;
}

.main-content section.ten-reasons ul li:before, .main-content section#ten-reasons ul li:before {
  position: absolute;
  left: 0;
  top: 0.875rem;
  background: url("/frontend/images/shared/checkbox.png") no-repeat;
  background-size: contain;
  content: "";
  width: 24px;
  height: 21px;
  display: inline-block;
}

.img-parent.left {
  text-align: left;
}

.img-parent.center {
  text-align: center;
}

.img-parent.right {
  text-align: right;
}

.boxes .container > .row {
  margin-bottom: 25px;
}

.boxes .container > .row:last-of-type {
  margin-bottom: 0;
}

.boxes .box {
  height: 100%;
  padding: 25px;
}

.boxes .box .right a {
  text-align: center;
  display: block;
}

.boxes .box h1.head {
  font-weight: lighter;
}

.boxes .box .subhead {
  margin-bottom: 20px;
  font-weight: normal;
}

.boxes .box .body p {
  margin-bottom: 20px;
}

.boxes .box .body p:last-child {
  margin-bottom: 0;
}

.boxes .box .link-text {
  font-weight: lighter;
}

[data-controller="custom_nodes"][data-action="home_pv"] section#hero,
[data-controller="custom_nodes"][data-action="view"] section#hero {
  min-height: 616px;
  padding-top: 150px;
  padding-bottom: 60px;
  background-color: #008c8c;
  background-color: #018888;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
}

[data-controller="custom_nodes"][data-action="home_pv"] section#hero .head,
[data-controller="custom_nodes"][data-action="view"] section#hero .head {
  font-size: 100px;
  font-weight: 900;
  font-family: Lato-Black;
}

[data-controller="custom_nodes"][data-action="home_pv"] section#hero h3,
[data-controller="custom_nodes"][data-action="view"] section#hero h3 {
  padding-bottom: 16px;
}

[data-controller="custom_nodes"][data-action="home_pv"] section#benefits .divider,
[data-controller="custom_nodes"][data-action="view"] section#benefits .divider {
  margin: 0 auto;
  width: 100%;
  border-bottom: 5px solid #6b6b6b;
  padding-top: 16px;
}

[data-controller="custom_nodes"][data-action="home_pv"] section#benefits .subhead,
[data-controller="custom_nodes"][data-action="view"] section#benefits .subhead {
  font-family: Lato-Black;
  padding-top: 16px;
}

.pagination ul {
  -webkit-padding-start: 0px;
}

.pagination .btn {
  margin: 4px;
  min-width: auto;
}

.pagination .btn a {
  display: inline-block;
}

.pagination .btn a, .pagination .btn a:active, .pagination .btn a:hover, .pagination .btn a:focus, .pagination .btn a:visited {
  color: inherit;
  text-decoration: none;
}

.pagination .btn a.current.btn-pv {
  background-color: #fff;
  background-color: #00b0b0;
}

.pagination .btn.current.btn-pv {
  background-color: #F3F3F3;
  color: #00b0b0;
}

.actions .btn-action {
  padding: 4px;
}

@media screen {
  div#preloader {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  div#preloader img {
    display: block;
  }
}

@media print {
  div#preloader,
  div#preloader img {
    visibility: hidden;
    display: none;
  }
}

form .form-group .box {
  min-height: 26px;
}

[data-plugin='contacts'][data-controller='contacts'][data-action='contact'] section#node-content .node-title {
  margin-bottom: 20px;
}

[data-plugin='contacts'][data-controller='contacts'][data-action='contact'] section#node-content .node-body {
  margin-bottom: 20px;
}

.responsive-img-wrapper {
  width: 100%;
  position: relative;
  left: 0;
  right: initial;
}

.responsive-img-wrapper.align-right {
  left: initial;
  right: 0 !important;
  float: right;
}

.responsive-img-wrapper > img {
  max-width: 100%;
  /*this style is required untill we remove .responsive class from body and make it's style global*/
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.list-style-1 {
  list-style-position: outside;
  margin: 0 0;
  padding: 0 20px;
}

.list-style-1 li {
  position: relative;
  margin: 0 0;
  padding-left: 15px;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.list-style-1 li:before {
  padding-left: 10px;
}

.btn-primary:disabled:hover,
disabled:hover {
  color: #fff !important;
}

.btn {
  min-width: 200px;
}

[class*=bg-color-] a:not(.btn) {
  color: inherit;
}

.sidebar {
  font-size: 85%;
}

.sidebar .block .block-title {
  font-size: 28px;
  font-family: Lato-Regular;
  color: #5E5E5E;
  padding-bottom: 4px;
  border-bottom: 1px solid #C2C2C2;
  margin-bottom: 8px;
}

.sidebar .block .node-info {
  margin-top: 4px;
  font-size: 90%;
}

.sidebar .block .node + .node {
  margin-top: 16px;
}

.sidebar .block + .block {
  margin-top: 32px;
}

.sidebar .block.block-popular-posts .node-featured-image {
  margin-top: 8px;
}

.sidebar .block.block-recent-posts .node-featured-image {
  padding-right: 0;
}

.sidebar .block.block-twitter-feed {
  margin-left: -10px;
  margin-right: -10px;
}

.sidebar .popular-posts-widget,
.sidebar .recent-posts-widget,
.sidebar .search-widget,
.sidebar .subscribe-widget,
.sidebar .twitter-widget {
  padding-bottom: 30px;
}

.sidebar .popular-posts-widget .subhead-parent,
.sidebar .recent-posts-widget .subhead-parent,
.sidebar .search-widget .subhead-parent,
.sidebar .subscribe-widget .subhead-parent,
.sidebar .twitter-widget .subhead-parent {
  border-bottom: 1px solid #4c4f54;
}

.sidebar .popular-posts-widget .subhead-parent .subhead,
.sidebar .recent-posts-widget .subhead-parent .subhead,
.sidebar .search-widget .subhead-parent .subhead,
.sidebar .subscribe-widget .subhead-parent .subhead,
.sidebar .twitter-widget .subhead-parent .subhead {
  font-size: 22px;
  margin: 0px;
  padding-bottom: 10px;
}

.sidebar .popular-posts-widget .subcontent,
.sidebar .recent-posts-widget .subcontent,
.sidebar .search-widget .subcontent,
.sidebar .subscribe-widget .subcontent,
.sidebar .twitter-widget .subcontent {
  font-size: 14px;
  padding-top: 15px;
}

.sidebar .popular-posts-widget .subcontent h4,
.sidebar .recent-posts-widget .subcontent h4,
.sidebar .search-widget .subcontent h4,
.sidebar .subscribe-widget .subcontent h4,
.sidebar .twitter-widget .subcontent h4 {
  font-size: 18px;
}

.sidebar .popular-posts-widget .subcontent img,
.sidebar .recent-posts-widget .subcontent img,
.sidebar .search-widget .subcontent img,
.sidebar .subscribe-widget .subcontent img,
.sidebar .twitter-widget .subcontent img {
  padding-top: 5px;
}

.sidebar .popular-posts-widget .subcontent p,
.sidebar .recent-posts-widget .subcontent p,
.sidebar .search-widget .subcontent p,
.sidebar .subscribe-widget .subcontent p,
.sidebar .twitter-widget .subcontent p {
  margin: 0px;
  padding-bottom: 10px;
}

.content-area .nodes .node-term-title,
.content-area #node-content .node-term-title {
  margin-bottom: 24px;
}

.content-area .nodes .node-term-title .tag-archives,
.content-area #node-content .node-term-title .tag-archives {
  font-size: 20px;
}

.content-area .nodes .node-term-title .head,
.content-area #node-content .node-term-title .head {
  font-size: 32px;
}

.content-area .nodes .node + .node,
.content-area #node-content .node + .node {
  margin-top: 32px;
}

.content-area .nodes .node-type-pv-blog,
.content-area #node-content .node-type-pv-blog {
  padding-bottom: 0px;
}

.content-area .nodes .node-title,
.content-area #node-content .node-title {
  font-size: 28px;
  padding-bottom: 6px;
  font-weight: 600;
}

.content-area .nodes .node-info,
.content-area #node-content .node-info {
  font-size: 14px;
  padding-bottom: 16px;
}

.content-area .nodes .node-info i,
.content-area #node-content .node-info i {
  font-size: 18px;
  padding-right: 5px;
}

.content-area .nodes img,
.content-area #node-content img {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
  padding-right: 0px;
  max-width: 100%;
}

.content-area .nodes .node-excerpt,
.content-area #node-content .node-excerpt {
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.content-area .nodes .continue-reading,
.content-area #node-content .continue-reading {
  font-size: 18px;
  padding-top: 10px;
}

.content-area .nodes .continue-reading:after,
.content-area #node-content .continue-reading:after {
  color: #00b0b0;
  padding-left: 8px;
  content: "\f0da";
  font-family: FontAwesome;
  display: inline-block;
}

.content-area .nodes .node-more-info,
.content-area #node-content .node-more-info {
  padding-top: 10px;
  font-size: 16px;
  color: #8f8f8f;
}

.content-area .nodes .node-body h1,
.content-area #node-content .node-body h1 {
  font-size: 20px;
  padding-bottom: 10px;
  margin: 0px;
}

.content-area .nodes .node-body p,
.content-area #node-content .node-body p {
  font-size: 16px;
  padding-bottom: 10px;
  margin: 0px;
}

.content-area .nodes .node-paging,
.content-area #node-content .node-paging {
  margin-top: 24px;
  border-top: 1px solid #E6E6E6;
}

.content-area .nodes .node-paging .pagination,
.content-area #node-content .node-paging .pagination {
  margin: 24px 0;
}

.panel {
  border-width: 3px;
  box-shadow: 0 1px 2px #eef1f3;
  margin-bottom: 22px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
}

.panel-info {
  border-color: #d8e2e4;
}

.panel-info .panel-heading {
  padding: 10px;
  background-image: linear-gradient(to bottom, #eef1f3 0, #dee5e9 100%);
  background-repeat: repeat-x;
}

.panel-info .panel-heading .tool-logo {
  color: #00b0b0;
}

.panel-info .panel-body {
  padding: 10px;
  color: #4c4f54 !important;
}

.panel-info .panel-body .more {
  color: #00b0b0;
}

.panel-info .panel-body .client-name {
  padding: 5px;
  color: #00b0b0;
  display: block;
}

section#footer .container {
  font-size: 1rem;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

section#footer .container a, section#footer .container a:visited, section#footer .container a:hover {
  color: #fff;
}

section#footer .container p:last-of-type {
  margin-bottom: 0;
}

@media all and (max-width: 991px) {
  .navbar-collapse.in {
    overflow-y: initial;
  }
  .navbar-collapse {
    position: absolute;
    right: 0;
    top: 50px;
  }
  .pv-navbar-main .navbar-nav,
  .navbar-tool .navbar-nav {
    overflow: visible;
    float: right;
    width: 160px;
    background-color: #F3F3F3;
    border: 1px solid #fff;
  }
  .pv-navbar-main .navbar-nav > li:not(:last-child),
  .navbar-tool .navbar-nav > li:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  .pv-navbar-main .navbar-nav > li > a,
  .pv-navbar-main .navbar-nav > li > a.active,
  .pv-navbar-main .navbar-nav > li > a:focus,
  .pv-navbar-main .navbar-nav > li > a:visited,
  .pv-navbar-main .navbar-nav > li > a.active:hover,
  .pv-navbar-main .navbar-nav > li > a:hover,
  .navbar-tool .navbar-nav > li > a,
  .navbar-tool .navbar-nav > li > a.active,
  .navbar-tool .navbar-nav > li > a:focus,
  .navbar-tool .navbar-nav > li > a:visited,
  .navbar-tool .navbar-nav > li > a.active:hover,
  .navbar-tool .navbar-nav > li > a:hover {
    color: #4c4f54 !important;
    transition: font 0.3s ease;
    padding: 10px;
  }
  .pv-navbar-main .navbar-nav .dropdown,
  .navbar-tool .navbar-nav .dropdown {
    z-index: 10004;
    position: relative;
  }
  .pv-navbar-main .navbar-nav > .dropdown.open,
  .navbar-tool .navbar-nav > .dropdown.open {
    z-index: 10004;
    position: relative;
  }
  .pv-navbar-main .navbar-nav .dropdown-menu,
  .navbar-tool .navbar-nav .dropdown-menu {
    z-index: 10014;
    overflow: visible;
    width: 120px;
    position: absolute;
    top: 0;
    right: 100%;
    left: auto;
  }
  .pv-navbar-main .navbar-nav .navbar-collapse,
  .navbar-tool .navbar-nav .navbar-collapse {
    overflow: visible;
  }
}

.pv-navbar-main,
.navbar-tool {
  font-family: Lato-Regular;
  font-weight: normal;
  margin: 0px;
  padding: .6rem 0rem;
}

.pv-navbar-main .nav-link,
.navbar-tool .nav-link {
  transition: all .3s ease-in-out;
}

.pv-navbar-main.navbar,
.navbar-tool.navbar {
  min-height: initial;
}

.pv-navbar-main .navbar-nav > .dropdown .caret,
.navbar-tool .navbar-nav > .dropdown .caret {
  display: none;
}

.pv-navbar-main .navbar-nav .dropdown-menu,
.navbar-tool .navbar-nav .dropdown-menu {
  background-color: #F3F3F3;
  padding: 0px;
  border: 1px solid #fff;
}

.pv-navbar-main .navbar-nav .dropdown-menu > li:not(:last-child),
.navbar-tool .navbar-nav .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.pv-navbar-main .navbar-nav .dropdown-menu > li > a,
.navbar-tool .navbar-nav .dropdown-menu > li > a {
  padding: 8px 15px;
  white-space: normal;
  text-align: left;
  color: #4c4f54;
  display: block;
  font-size: 1rem;
}

.pv-navbar-main .navbar-nav .dropdown-menu > li .active,
.pv-navbar-main .navbar-nav .dropdown-menu > li :hover,
.navbar-tool .navbar-nav .dropdown-menu > li .active,
.navbar-tool .navbar-nav .dropdown-menu > li :hover {
  background-color: #00b0b0;
  color: #fff;
}

.navbar-tool {
  z-index: 1001;
}

.navbar-tool .navbar-header {
  z-index: 1002;
  margin: auto;
}

.navbar-tool .navbar-nav > li > a,
.navbar-tool .navbar-nav > li > a:focus,
.navbar-tool .navbar-nav > li > a:visited,
.navbar-tool .navbar-nav > li > a:hover {
  font-size: 1.125rem;
}

@media all and (min-width: 1200px) {
  .pv-navbar-main .navbar-nav > .active {
    margin-top: -11px;
  }
  .pv-navbar-main .navbar-nav > .active > a {
    padding-top: 15px;
    border-top: 4px solid #00b0b0;
  }
  .pv-navbar-main .navbar-nav a.register {
    padding-right: 0;
  }
  .pv-navbar-main .navbar-nav a.register:after {
    content: ' | ';
  }
  .pv-navbar-main .navbar-nav a.login {
    font-weight: bold;
    padding-left: 4px;
  }
}

.main-content {
  padding-top: 70px;
}

body.transparent-menu #header .logo-nav {
  background-color: rgba(0, 88, 88, 0.5);
}

body.transparent-menu .main-content {
  padding-top: 0;
}

section#flash-message {
  position: fixed;
  top: 120px;
  right: 0;
  z-index: 1100;
}

section#flash-message > .inner {
  z-index: 1101;
  position: relative;
  top: 0;
  right: 0;
}

section#flash-message > .inner > .alert {
  position: absolute;
  top: 0;
  right: 0;
  transition: right .6s ease-out;
  max-width: 400px;
  width: 400px;
}

section#flash-message > .inner > .toggle-buttons {
  z-index: 1101;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
}

section#flash-message > .inner > .toggle-buttons button {
  background-color: #00b0b0;
  color: #fff;
  border: 0px none;
}

section#flash-message.pshow .alert {
  right: 0;
}

section#flash-message.pshow .toggle-buttons #show-msg {
  display: none;
}

section#flash-message.pshow .toggle-buttons #hide-msg {
  display: block;
}

section#flash-message.phide .alert {
  right: -400px;
}

section#flash-message.phide .toggle-buttons #hide-msg {
  display: none;
}

section#flash-message.phide .toggle-buttons #show-msg {
  display: block;
}

section#header {
  position: fixed;
  width: 100%;
  z-index: 1002;
}

section#header .search-wrap {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  background-color: #F3F3F3;
}

section#header .search-wrap .container {
  padding: 0 15px;
}

section#header .search-wrap .search-form {
  background-color: #F3F3F3;
  height: 64px;
  z-index: 1;
}

section#header .search-wrap .search-form .form-group {
  height: 64px;
  width: 100%;
  margin: 0 0;
  padding: 0 0;
}

section#header .search-wrap .search-form .form-group .form-control {
  width: inherit;
  height: 64px;
  line-height: 32px;
  vertical-align: middle;
  background-color: #F3F3F3;
  border: none;
  box-shadow: none;
  text-align: right;
  padding: 16px 64px 16px 0;
  z-index: 2;
}

section#header .search-wrap .search-form .btn {
  z-index: 3;
  padding: 0 0;
  color: #00b0b0 !important;
  border: 0 none;
  min-width: 40px;
  background: none;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 12px;
  overflow: hidden;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

section#header .search-wrap .search-form .btn [class*=" icon-"], section#header .search-wrap .search-form .btn [class^=icon-] {
  color: #00b0b0 !important;
  z-index: 4;
}

section#header .logo-nav {
  position: initial;
  transition: opacity 0.3s ease;
  background-color: #00b0b0;
}

section#header .logo-nav .container {
  position: relative;
}

section#header .logo-nav .container .logo {
  position: absolute;
  z-index: 101;
  top: 6px;
  left: 15px;
}

section#header .logo-nav .container .logo a {
  display: block;
  z-index: 111;
}

section#header .logo-nav .container .logo img {
  height: 50px;
  transition: height 0.3s ease;
}

@media all and (min-width: 992px) {
  section#header .logo-nav .container nav {
    padding-left: 100px;
    /*for logo*/
    padding-right: 70px;
    /*for search button*/
    position: relative;
    z-index: 100;
  }
}

section#header .logo-nav .container .search-trigger {
  min-width: initial;
  background-color: transparent;
  min-width: 40px;
  color: #fff;
  border: 0 none;
}

@media all and (min-width: 992px) {
  section#header .logo-nav .container .search-trigger {
    position: absolute;
    z-index: 102;
    top: 16px;
    right: 5px;
    color: #fff;
  }
}

section#header.search-open .search-wrap {
  height: 64px;
}

section#header.search-open .logo-nav {
  background-color: rgba(0, 88, 88, 0.7);
}

section#header.scrolled .logo-nav {
  background-color: #00b0b0;
}

section#header.scrolled .logo-nav .container .logo img {
  height: 45px;
}

section#header.scrolled .logo-nav .container .search-trigger {
  top: 12px;
}

section#header.scrolled .pv-navbar-main a, section#header.scrolled .pv-navbar-main a:visited, section#header.scrolled .pv-navbar-main a:hover {
  font-size: 18px;
}

@media all and (min-width: 1200px) {
  section#header.scrolled .pv-navbar-main li.active {
    margin-top: -8px;
  }
  section#header.scrolled .pv-navbar-main li.active > a {
    padding-top: 13px;
    border-top: 3px solid #00b0b0;
  }
}

@media all and (max-width: 991px) {
  section#header .logo-nav {
    max-height: 64px;
  }
  section#header .logo-nav .container .logo img {
    height: 48px;
  }
  section#header.scrolled .logo-nav {
    max-height: 58px;
  }
  section .search-trigger {
    position: relative;
    z-index: 102;
    top: 14px;
    right: 5px;
    color: #fff;
  }
}

.pv-navbar-main,
.navbar-tool {
  font-family: Lato-Regular;
  font-weight: normal;
  margin: 0px;
  padding: .6rem 0rem;
}

.pv-navbar-main .nav-link,
.navbar-tool .nav-link {
  transition: all .3s ease-in-out;
}

.pv-navbar-main.navbar,
.navbar-tool.navbar {
  min-height: initial;
}

.pv-navbar-main .navbar-nav > .dropdown .caret,
.navbar-tool .navbar-nav > .dropdown .caret {
  display: none;
}

.pv-navbar-main .navbar-nav .dropdown-menu,
.navbar-tool .navbar-nav .dropdown-menu {
  background-color: #F3F3F3;
  padding: 0px;
  border: 1px solid #fff;
}

.pv-navbar-main .navbar-nav .dropdown-menu > li:not(:last-child),
.navbar-tool .navbar-nav .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.pv-navbar-main .navbar-nav .dropdown-menu > li > a,
.navbar-tool .navbar-nav .dropdown-menu > li > a {
  padding: 8px 15px;
  white-space: normal;
  text-align: left;
  color: #4c4f54;
  display: block;
  font-size: 1rem;
}

.pv-navbar-main .navbar-nav .dropdown-menu > li .active,
.pv-navbar-main .navbar-nav .dropdown-menu > li :hover,
.navbar-tool .navbar-nav .dropdown-menu > li .active,
.navbar-tool .navbar-nav .dropdown-menu > li :hover {
  background-color: #00b0b0;
  color: #fff;
}

.navbar-tool {
  z-index: 1001;
}

.navbar-tool .navbar-header {
  z-index: 1002;
  margin: auto;
}

.navbar-tool .navbar-nav > li > a,
.navbar-tool .navbar-nav > li > a:focus,
.navbar-tool .navbar-nav > li > a:visited,
.navbar-tool .navbar-nav > li > a:hover {
  font-size: 1.125rem;
}

@media all and (min-width: 1200px) {
  .pv-navbar-main .navbar-nav > .active {
    margin-top: -11px;
  }
  .pv-navbar-main .navbar-nav > .active > a {
    padding-top: 15px;
    border-top: 4px solid #00b0b0;
  }
  .pv-navbar-main .navbar-nav a.register {
    padding-right: 0;
  }
  .pv-navbar-main .navbar-nav a.register:after {
    content: ' | ';
  }
  .pv-navbar-main .navbar-nav a.login {
    font-weight: bold;
    padding-left: 4px;
  }
}

@media all and (max-width: 991px) {
  .navbar-collapse.in {
    overflow-y: initial;
  }
  .navbar-collapse {
    position: absolute;
    right: 0;
    top: 50px;
  }
  .pv-navbar-main .navbar-nav,
  .navbar-tool .navbar-nav {
    overflow: visible;
    float: right;
    width: 160px;
    background-color: #F3F3F3;
    border: 1px solid #fff;
  }
  .pv-navbar-main .navbar-nav > li:not(:last-child),
  .navbar-tool .navbar-nav > li:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  .pv-navbar-main .navbar-nav > li > a,
  .pv-navbar-main .navbar-nav > li > a.active,
  .pv-navbar-main .navbar-nav > li > a:focus,
  .pv-navbar-main .navbar-nav > li > a:visited,
  .pv-navbar-main .navbar-nav > li > a.active:hover,
  .pv-navbar-main .navbar-nav > li > a:hover,
  .navbar-tool .navbar-nav > li > a,
  .navbar-tool .navbar-nav > li > a.active,
  .navbar-tool .navbar-nav > li > a:focus,
  .navbar-tool .navbar-nav > li > a:visited,
  .navbar-tool .navbar-nav > li > a.active:hover,
  .navbar-tool .navbar-nav > li > a:hover {
    color: #4c4f54 !important;
    transition: font 0.3s ease;
    padding: 10px;
  }
  .pv-navbar-main .navbar-nav .dropdown,
  .navbar-tool .navbar-nav .dropdown {
    z-index: 10004;
    position: relative;
  }
  .pv-navbar-main .navbar-nav > .dropdown.open,
  .navbar-tool .navbar-nav > .dropdown.open {
    z-index: 10004;
    position: relative;
  }
  .pv-navbar-main .navbar-nav .dropdown-menu,
  .navbar-tool .navbar-nav .dropdown-menu {
    z-index: 10014;
    overflow: visible;
    width: 120px;
    position: absolute;
    top: 0;
    right: 100%;
    left: auto;
  }
  .pv-navbar-main .navbar-nav .navbar-collapse,
  .navbar-tool .navbar-nav .navbar-collapse {
    overflow: visible;
  }
}

[data-plugin='cart'][data-controller='products'][data-action="index"] .main-content {
  padding-top: 0px;
}

@media all and (max-width: 767px) {
  [data-plugin='cart'][data-controller='products'][data-action="index"] .main-content section .container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

[data-plugin='cart'][data-controller='products'][data-action="index"] .main-content section#hero {
  min-height: initial;
}

.shopping-cart #flash-message .container {
  width: initial;
}

[data-plugin='users'] .popover-content span {
  display: block;
  padding: 5px 20px;
}

[data-plugin='users'] .popover-content span .glyphicon {
  font-size: 90%;
  margin-right: 10px;
  display: inline-block;
}

[data-plugin='users'] .popover-content .good {
  color: #3c763d;
}

[data-plugin='users'] .popover-content .bad {
  color: #a94442;
}

[data-plugin="cart"][data-controller="products"][data-action="index"] .panel .panel-collapse {
  border: 0 none;
}

[data-plugin="cart"][data-controller="products"][data-action="index"] .panel .panel-collapse .panel-body {
  padding: 0 0;
}

[data-plugin="cart"][data-controller="products"][data-action="index"] .shopping-cart .flash-message .container {
  width: auto;
}

@media all and (min-width: 768px) {
  [data-controller="toolboxes"] .btn {
    min-width: 250px;
  }
}

@media all and (max-width: 767px) {
  body.node-slug-home .main-content section#benefits .boxes .box:last-child {
    padding-bottom: 0;
  }
}

body.node-slug-home .main-content section#hero .head {
  font-size: 100px;
  font-weight: 900;
  font-family: Lato-Black;
}

@media all and (max-width: 767px) {
  body.node-slug-home .main-content section#hero .head {
    font-size: 60px;
  }
}

/*
VALUES EXERCISE STYLE SHEET
#0F459C blue border
#F7F5F6 grey background
*/
[data-plugin='values_exercise'] #main-content {
  padding-top: 70px;
}

[data-plugin='values_exercise'] #main-content section#pv-hero .container, [data-plugin='values_exercise'] #main-content section#pv-crumb-nav .container {
  padding-top: 6.4px;
  padding-bottom: 12.8px;
}

[data-plugin='values_exercise'] #main-content section#pv-content .container {
  padding-top: 32px;
  padding-bottom: 32px;
}

[data-plugin='values_exercise'] #main-content section#pv-form .container {
  padding-top: 32px;
  padding-bottom: 32px;
}

@media (max-width: 767px) {
  [data-plugin='values_exercise'] #main-content .tool-container {
    padding-left: 3px;
    padding-right: 3px;
  }
  [data-plugin='values_exercise'] #main-content .tool-container > .row {
    margin-left: -3px;
    margin-right: -3px;
  }
  [data-plugin='values_exercise'] #main-content .panel {
    border-width: 0px;
    border: 0px none;
  }
}

@media (min-width: 768px) {
  [data-plugin='values_exercise'] #main-content .panel {
    border-width: 1px;
    border: 1px solid #00b0b0;
  }
}

[data-plugin='values_exercise'] #main-content fieldset {
  clear: both;
  position: relative;
  height: auto;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  [data-plugin='values_exercise'] #main-content fieldset {
    padding: 16px;
    border-radius: 5px;
  }
  [data-plugin='values_exercise'] #main-content fieldset legend {
    /*reset bootstrap*/
    display: block;
    width: auto;
    padding: 0;
    margin-bottom: 0;
    line-height: inherit;
    /*other changes*/
    background: #fff;
    font-weight: normal;
    padding: 5px 10px;
  }
}

[data-plugin='values_exercise'] #main-content .search-blocks {
  padding: 5px 0px;
  margin-bottom: 0;
  background: none;
  background-color: transparent;
}

[data-plugin='values_exercise'] #main-content .form-group.required label:after {
  content: "";
}

[data-plugin='values_exercise'] #main-content .error-block.checked {
  background: none;
}

[data-plugin='values_exercise'] #main-content .c-divider {
  background: #F3F3F3;
  width: 100%;
  height: 2px;
  clear: both;
  margin: 10px 0;
  padding: 0 0 0 0;
}

[data-plugin='values_exercise'] #main-content .veUserCriterias .select .control-label {
  padding-top: 0px;
}

[data-plugin='values_exercise'] #main-content #pro-values .container {
  padding-top: 0;
}

[data-plugin='values_exercise'] #main-content .node-title h1 {
  font-family: Lato-Regular;
  color: #4c4f54;
}

[data-plugin='values_exercise'] #main-content .btn {
  min-width: initial;
  margin-bottom: 20px;
}

[data-plugin='values_exercise'] #main-content .btn:hover {
  color: #00b0b0;
  background: #fff;
}

[data-plugin='values_exercise'][data-controller='ve_values'][data-action='index'] .row-buttons {
  text-align: center;
}

[data-plugin='values_exercise'][data-controller='ve_values'][data-action='index'] .row-buttons .btn {
  max-width: 420px;
  margin: 10px 0;
  display: inline-block;
}

[data-plugin='values_exercise'][data-controller='ve_user_criterias'][data-action='index'] svg circle.a:hover {
  fill: #00b0b0;
}

[data-plugin='values_exercise'][data-controller='ve_user_criterias'][data-action='select'] .stars:before, [data-plugin='values_exercise'][data-controller='ve_user_criterias'][data-action='select'] .stars:after {
  content: "\f005\f005\f005\f005";
  font-family: FontAwesome;
}

[data-plugin='values_exercise'][data-controller='ve_user_criterias'][data-action='select'] .stars.stars-1:after {
  content: "\f005";
  font-family: FontAwesome;
}

[data-plugin='values_exercise'][data-controller='ve_user_criterias'][data-action='select'] .stars.stars-2:after {
  content: "\f005\f005";
  font-family: FontAwesome;
}

[data-plugin='values_exercise'][data-controller='ve_user_criterias'][data-action='select'] .stars.stars-3:after {
  content: "\f005\f005\f005";
  font-family: FontAwesome;
}

[data-plugin='values_exercise'][data-controller='ve_user_criterias'][data-action='select'] .stars.stars-4:after {
  content: "\f005\f005\f005\f005";
  font-family: FontAwesome;
}

@media all and (max-width: 767px) {
  [data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] .star-rating {
    display: inline-block;
  }
  [data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] .visible-xs-inline-block {
    min-width: 130px;
  }
}

[data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] .title {
  font-family: Lato-Regular;
}

[data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] .row-role-info p {
  margin-bottom: 12px;
}

[data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] fieldset {
  line-height: 1;
}

[data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] fieldset h2 {
  margin: 0 0;
  line-height: 28px;
}

[data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] fieldset .form-group {
  margin: 0 0;
  clear: both;
  margin-left: -15px;
  margin-right: -15px;
}

[data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] fieldset .form-group:after {
  content: "";
}

[data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] fieldset .form-group p {
  margin: 0 0;
  padding: 0 0;
}

@media all and (min-width: 768px) {
  [data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] fieldset .form-group {
    padding-bottom: 24px;
  }
}

@media all and (max-width: 767px) {
  [data-plugin='values_exercise'][data-controller='ve_user_criteria_evaluations'] fieldset .form-group > div {
    margin-top: 5px;
  }
}

[data-plugin='values_exercise'][data-controller='ve_user_minimum_requirements'][data-action='save'] .fieldsetArea {
  height: auto;
}

[data-plugin='values_exercise'][data-controller='ve_user_minimum_requirements'][data-action='save'] .fieldsetArea:after,
[data-plugin='values_exercise'][data-controller='ve_user_minimum_requirements'][data-action='save'] .form-group:after {
  clear: both;
  content: " ";
}

[data-plugin='values_exercise'][data-controller='ve_user_results'][data-action='view'] svg#legend g.past rect {
  fill: #008c8c;
}

[data-plugin='values_exercise'][data-controller='ve_user_results'][data-action='view'] svg#legend g.future rect {
  fill: #66cfcf;
}

/*eo valuesExercise */
@media (max-width: 768px) {
  .veUserMinimumRequirements .save select.ve_minimum_requirement_id {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .veUserCriterias .prioritize input.points {
    margin-bottom: 10px;
  }
}

.veUserMinimumRequirementEvaluations .future .min-req-table-header .col-3 h3 {
  text-align: center;
}

.veUserMinimumRequirementEvaluations .future .min-req-table-body .col-3 select,
.veUserMinimumRequirementEvaluations .future .min-req-table-body .col-3 .selectize-control {
  width: 140px;
  margin: 0 auto;
}

.veUserResults.result td.rank {
  text-align: center;
}

.veUserResults.result td.score {
  text-align: center;
}

.veUserResults.result th.rank {
  text-align: center;
  width: 220px;
}

.veUserResults.result th.score {
  text-align: center;
}

.valuesExercise td.actions {
  min-width: 100px;
}

.actions.buttons {
  width: 400px;
  margin-left: 100px;
  padding-bottom: 20px;
}

.veUserResults .report .btn > .glyphicon {
  top: 3px;
}

.progress-tool {
  height: 3px;
  overflow: visible;
}

.progress-tool .progress-bar {
  position: relative;
  z-index: 4;
  height: 3px;
}

.progress-tool .progress-bar span {
  display: block;
  z-index: 5;
  background-color: #00b0b0;
  position: absolute;
  top: 0;
  right: -25px;
  border-radius: 3px;
  padding: 8px 6px;
  font-size: 0.9375rem;
}

[type=reset], [type=submit],
button, html [type=button] {
  -webkit-appearance: none;
}

.c-divider {
  background: #F3F3F3;
  width: 100%;
  height: 2px;
  clear: both;
  margin: 10px 0;
  padding: 0 0 0 0;
}

[data-layout="blog"] section#header .logo-nav {
  background-color: rgba(0, 0, 0, 0.2);
}

[data-layout="blog"] section#header.search-open .logo-nav {
  background-color: rgba(0, 0, 0, 0.4);
}

[data-layout="blog"] section#header.scrolled .logo-nav {
  background-color: #7b7b7b;
}

[data-layout="blog"] .main-content section#hero {
  background-image: url("/frontend/images/blog/hero-greyscale.png");
  background-color: #7b7b7b;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 0px;
}

[data-layout="blog"] .main-content section#hero #testimonials-slider > .row:not(:first-child) {
  display: none;
}

[data-layout="blog"] .main-content section#hero .container {
  padding-top: 112px;
  padding-bottom: 70px;
}

[data-layout="blog"] .main-content section#hero a {
  font-family: Lato-Regular;
}

[data-layout="blog"] .main-content section#hero a [class^="icon-"] {
  padding-left: 4px;
}

[data-layout="blog"] .main-content section#hero a.after-icon-caret-right {
  content: "\f0da";
  font-family: FontAwesome;
}

/**
using !important https://css-tricks.com/specifics-on-css-specificity/
 */
.margin-top-4 {
  margin-top: 4px !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-bottom-4 {
  padding-bottom: 4px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.padding-bottom-12 {
  padding-bottom: 12px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.padding-top-16 {
  padding-top: 16px !important;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.padding-top-24 {
  padding-top: 24px !important;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

.margin-top-28 {
  margin-top: 28px !important;
}

.margin-bottom-28 {
  margin-bottom: 28px !important;
}

.padding-top-28 {
  padding-top: 28px !important;
}

.padding-bottom-28 {
  padding-bottom: 28px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

.padding-top-32 {
  padding-top: 32px !important;
}

.padding-bottom-32 {
  padding-bottom: 32px !important;
}

.margin-top-36 {
  margin-top: 36px !important;
}

.margin-bottom-36 {
  margin-bottom: 36px !important;
}

.padding-top-36 {
  padding-top: 36px !important;
}

.padding-bottom-36 {
  padding-bottom: 36px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.margin-top-44 {
  margin-top: 44px !important;
}

.margin-bottom-44 {
  margin-bottom: 44px !important;
}

.padding-top-44 {
  padding-top: 44px !important;
}

.padding-bottom-44 {
  padding-bottom: 44px !important;
}

.margin-top-48 {
  margin-top: 48px !important;
}

.margin-bottom-48 {
  margin-bottom: 48px !important;
}

.padding-top-48 {
  padding-top: 48px !important;
}

.padding-bottom-48 {
  padding-bottom: 48px !important;
}

.margin-top-52 {
  margin-top: 52px !important;
}

.margin-bottom-52 {
  margin-bottom: 52px !important;
}

.padding-top-52 {
  padding-top: 52px !important;
}

.padding-bottom-52 {
  padding-bottom: 52px !important;
}

.margin-top-56 {
  margin-top: 56px !important;
}

.margin-bottom-56 {
  margin-bottom: 56px !important;
}

.padding-top-56 {
  padding-top: 56px !important;
}

.padding-bottom-56 {
  padding-bottom: 56px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.padding-top-60 {
  padding-top: 60px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.margin-top-64 {
  margin-top: 64px !important;
}

.margin-bottom-64 {
  margin-bottom: 64px !important;
}

.padding-top-64 {
  padding-top: 64px !important;
}

.padding-bottom-64 {
  padding-bottom: 64px !important;
}

@media all and (max-width: 767px) {
  .margin-top-4-xs {
    margin-top: 4px !important;
  }
  .margin-bottom-4-xs {
    margin-bottom: 4px !important;
  }
  .padding-top-4-xs {
    padding-top: 4px !important;
  }
  .padding-bottom-4-xs {
    padding-bottom: 4px !important;
  }
  .margin-top-8-xs {
    margin-top: 8px !important;
  }
  .margin-bottom-8-xs {
    margin-bottom: 8px !important;
  }
  .padding-top-8-xs {
    padding-top: 8px !important;
  }
  .padding-bottom-8-xs {
    padding-bottom: 8px !important;
  }
  .margin-top-12-xs {
    margin-top: 12px !important;
  }
  .margin-bottom-12-xs {
    margin-bottom: 12px !important;
  }
  .padding-top-12-xs {
    padding-top: 12px !important;
  }
  .padding-bottom-12-xs {
    padding-bottom: 12px !important;
  }
  .margin-top-16-xs {
    margin-top: 16px !important;
  }
  .margin-bottom-16-xs {
    margin-bottom: 16px !important;
  }
  .padding-top-16-xs {
    padding-top: 16px !important;
  }
  .padding-bottom-16-xs {
    padding-bottom: 16px !important;
  }
  .margin-top-20-xs {
    margin-top: 20px !important;
  }
  .margin-bottom-20-xs {
    margin-bottom: 20px !important;
  }
  .padding-top-20-xs {
    padding-top: 20px !important;
  }
  .padding-bottom-20-xs {
    padding-bottom: 20px !important;
  }
  .margin-top-24-xs {
    margin-top: 24px !important;
  }
  .margin-bottom-24-xs {
    margin-bottom: 24px !important;
  }
  .padding-top-24-xs {
    padding-top: 24px !important;
  }
  .padding-bottom-24-xs {
    padding-bottom: 24px !important;
  }
  .margin-top-28-xs {
    margin-top: 28px !important;
  }
  .margin-bottom-28-xs {
    margin-bottom: 28px !important;
  }
  .padding-top-28-xs {
    padding-top: 28px !important;
  }
  .padding-bottom-28-xs {
    padding-bottom: 28px !important;
  }
  .margin-top-32-xs {
    margin-top: 32px !important;
  }
  .margin-bottom-32-xs {
    margin-bottom: 32px !important;
  }
  .padding-top-32-xs {
    padding-top: 32px !important;
  }
  .padding-bottom-32-xs {
    padding-bottom: 32px !important;
  }
  .margin-top-36-xs {
    margin-top: 36px !important;
  }
  .margin-bottom-36-xs {
    margin-bottom: 36px !important;
  }
  .padding-top-36-xs {
    padding-top: 36px !important;
  }
  .padding-bottom-36-xs {
    padding-bottom: 36px !important;
  }
  .margin-top-40-xs {
    margin-top: 40px !important;
  }
  .margin-bottom-40-xs {
    margin-bottom: 40px !important;
  }
  .padding-top-40-xs {
    padding-top: 40px !important;
  }
  .padding-bottom-40-xs {
    padding-bottom: 40px !important;
  }
  .margin-top-44-xs {
    margin-top: 44px !important;
  }
  .margin-bottom-44-xs {
    margin-bottom: 44px !important;
  }
  .padding-top-44-xs {
    padding-top: 44px !important;
  }
  .padding-bottom-44-xs {
    padding-bottom: 44px !important;
  }
  .margin-top-48-xs {
    margin-top: 48px !important;
  }
  .margin-bottom-48-xs {
    margin-bottom: 48px !important;
  }
  .padding-top-48-xs {
    padding-top: 48px !important;
  }
  .padding-bottom-48-xs {
    padding-bottom: 48px !important;
  }
  .margin-top-52-xs {
    margin-top: 52px !important;
  }
  .margin-bottom-52-xs {
    margin-bottom: 52px !important;
  }
  .padding-top-52-xs {
    padding-top: 52px !important;
  }
  .padding-bottom-52-xs {
    padding-bottom: 52px !important;
  }
  .margin-top-56-xs {
    margin-top: 56px !important;
  }
  .margin-bottom-56-xs {
    margin-bottom: 56px !important;
  }
  .padding-top-56-xs {
    padding-top: 56px !important;
  }
  .padding-bottom-56-xs {
    padding-bottom: 56px !important;
  }
  .margin-top-60-xs {
    margin-top: 60px !important;
  }
  .margin-bottom-60-xs {
    margin-bottom: 60px !important;
  }
  .padding-top-60-xs {
    padding-top: 60px !important;
  }
  .padding-bottom-60-xs {
    padding-bottom: 60px !important;
  }
  .margin-top-64-xs {
    margin-top: 64px !important;
  }
  .margin-bottom-64-xs {
    margin-bottom: 64px !important;
  }
  .padding-top-64-xs {
    padding-top: 64px !important;
  }
  .padding-bottom-64-xs {
    padding-bottom: 64px !important;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .margin-top-4-sm {
    margin-top: 4px !important;
  }
  .margin-bottom-4-sm {
    margin-bottom: 4px !important;
  }
  .padding-top-4-sm {
    padding-top: 4px !important;
  }
  .padding-bottom-4-sm {
    padding-bottom: 4px !important;
  }
  .margin-top-8-sm {
    margin-top: 8px !important;
  }
  .margin-bottom-8-sm {
    margin-bottom: 8px !important;
  }
  .padding-top-8-sm {
    padding-top: 8px !important;
  }
  .padding-bottom-8-sm {
    padding-bottom: 8px !important;
  }
  .margin-top-12-sm {
    margin-top: 12px !important;
  }
  .margin-bottom-12-sm {
    margin-bottom: 12px !important;
  }
  .padding-top-12-sm {
    padding-top: 12px !important;
  }
  .padding-bottom-12-sm {
    padding-bottom: 12px !important;
  }
  .margin-top-16-sm {
    margin-top: 16px !important;
  }
  .margin-bottom-16-sm {
    margin-bottom: 16px !important;
  }
  .padding-top-16-sm {
    padding-top: 16px !important;
  }
  .padding-bottom-16-sm {
    padding-bottom: 16px !important;
  }
  .margin-top-20-sm {
    margin-top: 20px !important;
  }
  .margin-bottom-20-sm {
    margin-bottom: 20px !important;
  }
  .padding-top-20-sm {
    padding-top: 20px !important;
  }
  .padding-bottom-20-sm {
    padding-bottom: 20px !important;
  }
  .margin-top-24-sm {
    margin-top: 24px !important;
  }
  .margin-bottom-24-sm {
    margin-bottom: 24px !important;
  }
  .padding-top-24-sm {
    padding-top: 24px !important;
  }
  .padding-bottom-24-sm {
    padding-bottom: 24px !important;
  }
  .margin-top-28-sm {
    margin-top: 28px !important;
  }
  .margin-bottom-28-sm {
    margin-bottom: 28px !important;
  }
  .padding-top-28-sm {
    padding-top: 28px !important;
  }
  .padding-bottom-28-sm {
    padding-bottom: 28px !important;
  }
  .margin-top-32-sm {
    margin-top: 32px !important;
  }
  .margin-bottom-32-sm {
    margin-bottom: 32px !important;
  }
  .padding-top-32-sm {
    padding-top: 32px !important;
  }
  .padding-bottom-32-sm {
    padding-bottom: 32px !important;
  }
  .margin-top-36-sm {
    margin-top: 36px !important;
  }
  .margin-bottom-36-sm {
    margin-bottom: 36px !important;
  }
  .padding-top-36-sm {
    padding-top: 36px !important;
  }
  .padding-bottom-36-sm {
    padding-bottom: 36px !important;
  }
  .margin-top-40-sm {
    margin-top: 40px !important;
  }
  .margin-bottom-40-sm {
    margin-bottom: 40px !important;
  }
  .padding-top-40-sm {
    padding-top: 40px !important;
  }
  .padding-bottom-40-sm {
    padding-bottom: 40px !important;
  }
  .margin-top-44-sm {
    margin-top: 44px !important;
  }
  .margin-bottom-44-sm {
    margin-bottom: 44px !important;
  }
  .padding-top-44-sm {
    padding-top: 44px !important;
  }
  .padding-bottom-44-sm {
    padding-bottom: 44px !important;
  }
  .margin-top-48-sm {
    margin-top: 48px !important;
  }
  .margin-bottom-48-sm {
    margin-bottom: 48px !important;
  }
  .padding-top-48-sm {
    padding-top: 48px !important;
  }
  .padding-bottom-48-sm {
    padding-bottom: 48px !important;
  }
  .margin-top-52-sm {
    margin-top: 52px !important;
  }
  .margin-bottom-52-sm {
    margin-bottom: 52px !important;
  }
  .padding-top-52-sm {
    padding-top: 52px !important;
  }
  .padding-bottom-52-sm {
    padding-bottom: 52px !important;
  }
  .margin-top-56-sm {
    margin-top: 56px !important;
  }
  .margin-bottom-56-sm {
    margin-bottom: 56px !important;
  }
  .padding-top-56-sm {
    padding-top: 56px !important;
  }
  .padding-bottom-56-sm {
    padding-bottom: 56px !important;
  }
  .margin-top-60-sm {
    margin-top: 60px !important;
  }
  .margin-bottom-60-sm {
    margin-bottom: 60px !important;
  }
  .padding-top-60-sm {
    padding-top: 60px !important;
  }
  .padding-bottom-60-sm {
    padding-bottom: 60px !important;
  }
  .margin-top-64-sm {
    margin-top: 64px !important;
  }
  .margin-bottom-64-sm {
    margin-bottom: 64px !important;
  }
  .padding-top-64-sm {
    padding-top: 64px !important;
  }
  .padding-bottom-64-sm {
    padding-bottom: 64px !important;
  }
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  .margin-top-4-md {
    margin-top: 4px !important;
  }
  .margin-bottom-4-md {
    margin-bottom: 4px !important;
  }
  .padding-top-4-md {
    padding-top: 4px !important;
  }
  .padding-bottom-4-md {
    padding-bottom: 4px !important;
  }
  .margin-top-8-md {
    margin-top: 8px !important;
  }
  .margin-bottom-8-md {
    margin-bottom: 8px !important;
  }
  .padding-top-8-md {
    padding-top: 8px !important;
  }
  .padding-bottom-8-md {
    padding-bottom: 8px !important;
  }
  .margin-top-12-md {
    margin-top: 12px !important;
  }
  .margin-bottom-12-md {
    margin-bottom: 12px !important;
  }
  .padding-top-12-md {
    padding-top: 12px !important;
  }
  .padding-bottom-12-md {
    padding-bottom: 12px !important;
  }
  .margin-top-16-md {
    margin-top: 16px !important;
  }
  .margin-bottom-16-md {
    margin-bottom: 16px !important;
  }
  .padding-top-16-md {
    padding-top: 16px !important;
  }
  .padding-bottom-16-md {
    padding-bottom: 16px !important;
  }
  .margin-top-20-md {
    margin-top: 20px !important;
  }
  .margin-bottom-20-md {
    margin-bottom: 20px !important;
  }
  .padding-top-20-md {
    padding-top: 20px !important;
  }
  .padding-bottom-20-md {
    padding-bottom: 20px !important;
  }
  .margin-top-24-md {
    margin-top: 24px !important;
  }
  .margin-bottom-24-md {
    margin-bottom: 24px !important;
  }
  .padding-top-24-md {
    padding-top: 24px !important;
  }
  .padding-bottom-24-md {
    padding-bottom: 24px !important;
  }
  .margin-top-28-md {
    margin-top: 28px !important;
  }
  .margin-bottom-28-md {
    margin-bottom: 28px !important;
  }
  .padding-top-28-md {
    padding-top: 28px !important;
  }
  .padding-bottom-28-md {
    padding-bottom: 28px !important;
  }
  .margin-top-32-md {
    margin-top: 32px !important;
  }
  .margin-bottom-32-md {
    margin-bottom: 32px !important;
  }
  .padding-top-32-md {
    padding-top: 32px !important;
  }
  .padding-bottom-32-md {
    padding-bottom: 32px !important;
  }
  .margin-top-36-md {
    margin-top: 36px !important;
  }
  .margin-bottom-36-md {
    margin-bottom: 36px !important;
  }
  .padding-top-36-md {
    padding-top: 36px !important;
  }
  .padding-bottom-36-md {
    padding-bottom: 36px !important;
  }
  .margin-top-40-md {
    margin-top: 40px !important;
  }
  .margin-bottom-40-md {
    margin-bottom: 40px !important;
  }
  .padding-top-40-md {
    padding-top: 40px !important;
  }
  .padding-bottom-40-md {
    padding-bottom: 40px !important;
  }
  .margin-top-44-md {
    margin-top: 44px !important;
  }
  .margin-bottom-44-md {
    margin-bottom: 44px !important;
  }
  .padding-top-44-md {
    padding-top: 44px !important;
  }
  .padding-bottom-44-md {
    padding-bottom: 44px !important;
  }
  .margin-top-48-md {
    margin-top: 48px !important;
  }
  .margin-bottom-48-md {
    margin-bottom: 48px !important;
  }
  .padding-top-48-md {
    padding-top: 48px !important;
  }
  .padding-bottom-48-md {
    padding-bottom: 48px !important;
  }
  .margin-top-52-md {
    margin-top: 52px !important;
  }
  .margin-bottom-52-md {
    margin-bottom: 52px !important;
  }
  .padding-top-52-md {
    padding-top: 52px !important;
  }
  .padding-bottom-52-md {
    padding-bottom: 52px !important;
  }
  .margin-top-56-md {
    margin-top: 56px !important;
  }
  .margin-bottom-56-md {
    margin-bottom: 56px !important;
  }
  .padding-top-56-md {
    padding-top: 56px !important;
  }
  .padding-bottom-56-md {
    padding-bottom: 56px !important;
  }
  .margin-top-60-md {
    margin-top: 60px !important;
  }
  .margin-bottom-60-md {
    margin-bottom: 60px !important;
  }
  .padding-top-60-md {
    padding-top: 60px !important;
  }
  .padding-bottom-60-md {
    padding-bottom: 60px !important;
  }
  .margin-top-64-md {
    margin-top: 64px !important;
  }
  .margin-bottom-64-md {
    margin-bottom: 64px !important;
  }
  .padding-top-64-md {
    padding-top: 64px !important;
  }
  .padding-bottom-64-md {
    padding-bottom: 64px !important;
  }
}

@media all and (min-width: 1200px) {
  .margin-top-4-lg {
    margin-top: 4px !important;
  }
  .margin-bottom-4-lg {
    margin-bottom: 4px !important;
  }
  .padding-top-4-lg {
    padding-top: 4px !important;
  }
  .padding-bottom-4-lg {
    padding-bottom: 4px !important;
  }
  .margin-top-8-lg {
    margin-top: 8px !important;
  }
  .margin-bottom-8-lg {
    margin-bottom: 8px !important;
  }
  .padding-top-8-lg {
    padding-top: 8px !important;
  }
  .padding-bottom-8-lg {
    padding-bottom: 8px !important;
  }
  .margin-top-12-lg {
    margin-top: 12px !important;
  }
  .margin-bottom-12-lg {
    margin-bottom: 12px !important;
  }
  .padding-top-12-lg {
    padding-top: 12px !important;
  }
  .padding-bottom-12-lg {
    padding-bottom: 12px !important;
  }
  .margin-top-16-lg {
    margin-top: 16px !important;
  }
  .margin-bottom-16-lg {
    margin-bottom: 16px !important;
  }
  .padding-top-16-lg {
    padding-top: 16px !important;
  }
  .padding-bottom-16-lg {
    padding-bottom: 16px !important;
  }
  .margin-top-20-lg {
    margin-top: 20px !important;
  }
  .margin-bottom-20-lg {
    margin-bottom: 20px !important;
  }
  .padding-top-20-lg {
    padding-top: 20px !important;
  }
  .padding-bottom-20-lg {
    padding-bottom: 20px !important;
  }
  .margin-top-24-lg {
    margin-top: 24px !important;
  }
  .margin-bottom-24-lg {
    margin-bottom: 24px !important;
  }
  .padding-top-24-lg {
    padding-top: 24px !important;
  }
  .padding-bottom-24-lg {
    padding-bottom: 24px !important;
  }
  .margin-top-28-lg {
    margin-top: 28px !important;
  }
  .margin-bottom-28-lg {
    margin-bottom: 28px !important;
  }
  .padding-top-28-lg {
    padding-top: 28px !important;
  }
  .padding-bottom-28-lg {
    padding-bottom: 28px !important;
  }
  .margin-top-32-lg {
    margin-top: 32px !important;
  }
  .margin-bottom-32-lg {
    margin-bottom: 32px !important;
  }
  .padding-top-32-lg {
    padding-top: 32px !important;
  }
  .padding-bottom-32-lg {
    padding-bottom: 32px !important;
  }
  .margin-top-36-lg {
    margin-top: 36px !important;
  }
  .margin-bottom-36-lg {
    margin-bottom: 36px !important;
  }
  .padding-top-36-lg {
    padding-top: 36px !important;
  }
  .padding-bottom-36-lg {
    padding-bottom: 36px !important;
  }
  .margin-top-40-lg {
    margin-top: 40px !important;
  }
  .margin-bottom-40-lg {
    margin-bottom: 40px !important;
  }
  .padding-top-40-lg {
    padding-top: 40px !important;
  }
  .padding-bottom-40-lg {
    padding-bottom: 40px !important;
  }
  .margin-top-44-lg {
    margin-top: 44px !important;
  }
  .margin-bottom-44-lg {
    margin-bottom: 44px !important;
  }
  .padding-top-44-lg {
    padding-top: 44px !important;
  }
  .padding-bottom-44-lg {
    padding-bottom: 44px !important;
  }
  .margin-top-48-lg {
    margin-top: 48px !important;
  }
  .margin-bottom-48-lg {
    margin-bottom: 48px !important;
  }
  .padding-top-48-lg {
    padding-top: 48px !important;
  }
  .padding-bottom-48-lg {
    padding-bottom: 48px !important;
  }
  .margin-top-52-lg {
    margin-top: 52px !important;
  }
  .margin-bottom-52-lg {
    margin-bottom: 52px !important;
  }
  .padding-top-52-lg {
    padding-top: 52px !important;
  }
  .padding-bottom-52-lg {
    padding-bottom: 52px !important;
  }
  .margin-top-56-lg {
    margin-top: 56px !important;
  }
  .margin-bottom-56-lg {
    margin-bottom: 56px !important;
  }
  .padding-top-56-lg {
    padding-top: 56px !important;
  }
  .padding-bottom-56-lg {
    padding-bottom: 56px !important;
  }
  .margin-top-60-lg {
    margin-top: 60px !important;
  }
  .margin-bottom-60-lg {
    margin-bottom: 60px !important;
  }
  .padding-top-60-lg {
    padding-top: 60px !important;
  }
  .padding-bottom-60-lg {
    padding-bottom: 60px !important;
  }
  .margin-top-64-lg {
    margin-top: 64px !important;
  }
  .margin-bottom-64-lg {
    margin-bottom: 64px !important;
  }
  .padding-top-64-lg {
    padding-top: 64px !important;
  }
  .padding-bottom-64-lg {
    padding-bottom: 64px !important;
  }
}

.cke_editable section > .container {
  padding-top: 60px;
  padding-bottom: 60px;
}
