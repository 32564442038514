@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin boxes() {
  .container > .row {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
@mixin box() {
  height: 100%;
  padding: $box-large-padding;
  .right a {
    text-align: center;
    display: block;
  }
  h1.head {
    font-weight: lighter;
  }
  .subhead {
    margin-bottom: $box-small-padding;
    font-weight: normal;
  }
  .body {
    p {
      margin-bottom: $box-small-padding;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .link-text {
    font-weight: lighter;
  }
}


//panels
//------------------------------------
@mixin panelStyles($key) {
  .panel-heading {
    color:color(charcoal);
    .panel-title {
      > a {
        &:before {
          color: $key;
        }
        border-color: $key;
        &[aria-expanded="false"] {}
        &[aria-expanded="true"] {
          border-color: $key;
        }
      }
    }
    &+>.panel-collapse>.panel-body {
      border-top-color: transparent;
      border-top: 0 none;
    }
  }
}