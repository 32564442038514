[data-layout="blog"] {
  section#header {
    .logo-nav {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &.search-open .logo-nav {
      background-color: rgba(0, 0, 0, 0.4);
    }
    &.scrolled .logo-nav {
      background-color: rgba(123, 123, 123, 1);
    }
  }
  .main-content {
    section {
      &#hero {
        //twitter widget loading takes time that delay document.ready for slick
        #testimonials-slider > .row:not(:first-child){display: none;}
        background-image: url($image-url + 'blog/hero-greyscale.png');
        background-color: rgba(123, 123, 123, 1);;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 0px;
        .container {
          padding-top: 70px + 42px;
          padding-bottom: 70px;
        }
        a {
          font-family: Lato-Regular;
          [class^="icon-"] {
            padding-left: 4px;
          }
        }
        a.after-icon-caret-right {
          content: "\f0da";
          font-family: FontAwesome;
        }
      }
      &#blog {
        .container {
          //.nodes {} refer component nodes
        }
      }
    }
  }

}
