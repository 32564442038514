/*Forms
---------------------------------------*/

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

input[type="submit"] {
  line-height: $line-height-base;
  font-size: $font-size-base;
}

p.error-block {
  display: block; // account for any element using help-block
  margin-top: 5px;
  margin-bottom: 1px;
  color: color(error);
  font-style: italic;
}

.info-block {
  display: block; // account for any element using help-block
  margin-top: 5px;
  margin-bottom: 1px;
  color: color(info);
  font-style: italic;
}

.form-group.required label:after {
  color: #EE3322;
  content: "*";
  display: inline;
}

.has-error {
  // .form-control-validation(@state-danger-text; @state-danger-text; @state-danger-bg);
  // .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    color: color(error);
  }

  // .help-block {
  //   color: @text-color;
  // }
  // input[type="checkbox"] {
  // 	margin: 4px 0 0;
  // 	margin-top: 1px \9;  IE8-9
  // 	line-height: normal;
  // 	display: inline-block;
  // 	float: left;
  // }
  .radio {
    input[type="radio"] {
      clear: left;
    }
  }
}

.icon-help {
  /*cake form field error style*/
  // font-size: (@font-size-base * 0.9);
  // float:left;
  background: url("../images/help.gif") no-repeat 0 0;
  &:hover {
    background: url("../images/help_h.gif") no-repeat 0 0;
  }
  // padding: 0px 3px 0 21px;
  // margin: 0 15px 0 0; /*right margin for */
  // padding-left:21px;
  // font-style:italic;
  width: 14px;
  height: 14px;
  // display:block;
}

// .form.compact form .info-block {
.info-block {
  /*cake form field error style*/
  font-size: ($font-size-base * 0.9);
  // float:left;
  background: url("../images/info.gif") no-repeat 0 3px;
  // padding: 0px 3px 0 21px;
  // margin: 0 15px 0 0; /*right margin for */
  padding-left: 21px;
  font-style: italic;

}

// .form.compact form .error-block {
p.error-block {
  /*cake form field error style*/
  font-size: ($font-size-base * 0.9);
  // float:left;
  background: url("../images/unchecked.gif") no-repeat 0 3px;
  // padding: 0px 3px 0 21px;
  // margin: 0 15px 0 0; /*right margin for */
  padding-left: 21px;
  font-style: italic;
  color: #CB504A;
}

// .form.compact form .error-block.checked{
p.error-block.checked {
  background: url("../images/checked.gif") no-repeat 0 3px;
}

// .form.compact form .checked{
p.checked {
  background: url("../images/checked.gif") no-repeat 0 3px;
}

//do not use any styles on box, it breaks boxes box on /about page
.box {
  /*width:300px;*/
  //float: left;
  //clear: none;
  /*min-height: 40px;*/
  /*force php errors to move in new line*/
}

.c-inp-incl-msg {
  min-height: 73px;
}

label {
  font-weight: normal;
  //padding-top: 10px;
  color: color(dark-gray);
  //font-family: Lato-Regular;
  font-family: Lato-Light;
}

.has-feedback label ~ .form-control-feedback {
  top: 40px;
}

.selectize-control.single .selectize-input,
.form-control {
  //box-shadow: none!important;
  color: color(charcoal);
  //font-family: Lato-Regular;
  font-family: Lato-Light;
  //height: 34px;
  //height: 51px;
  //padding: 6px 12px;
  //padding: 10px 20px;
  //box-shadow: inset 0 1px 1px color(pvl5);
  //box-shadow: none;
  //border: 3px solid color(pvl6);
  //border: 3px solid color(gray);

  //border-radius: 0;
  //resolve border radius bug on select
  -webkit-appearance: none;

}

//selectize customization
//example page is http://loc.sparreyconsulting.com/career-pursuit-profile/past-current-roles/add

.selectize-control.multi .selectize-input {
  height: auto;
}

.selectize-control.multi .selectize-input > div {
  background-color: color(pvl1);
  color: #fff;
  padding: 5px 20px;
}
//.selectize-control.multi .selectize-input > div,
.selectize-input {
  &.input-active,
  &.focus {
    box-shadow: none;
    border-color: color(pv);
  }
}

//https://css-tricks.com/hang-on-placeholders/
[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: opacity 0.5s 0.5s ease;
  opacity: 0;
}







