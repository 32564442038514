/*bower_components/components-font-awesome*/
.rating-xl {
  font-size: 4.89rem;
}

.rating-lg {
  font-size: 3.91rem;
}

.rating-md {
  font-size: 3.13rem;
}

.rating-sm {
  font-size: 2.5rem;
}

.rating-xs {
  font-size: 1.3rem;
}

.rating-container-rtl .rating-stars:before,
.rating-container-rtl:before, .rating-container:before{}

.rating-container {
  .rating-stars {
    //display: none!important;
  }
}
.filled-stars{
  -webkit-text-stroke: 1px color(pvd3)!important;
  text-shadow:none!important;
  color:color(pv)!important;
}
.fa-star,
.fa-star-o,
.empty-stars{color:color(pv)!important;}

.rating-animate .filled-stars {
  transition: none !important;
  //transition: all 0.1s ease-out !important;
}

