//height and bg color should be applied to logo-nav
$navbar-height: 64px;
$navbar-scrolled-height: 58px;

@import "nav-small";
@import "nav-large";

.container{
  //border: 1px solid red;
}
//page-generic, should have no hero panel, this layout is for innere pages

.main-content {
  padding-top: 70px + 0px;
}

body.transparent-menu{
  #header{
    .logo-nav{
      //background-color: transparent;
      background-color: rgba(0, 88, 88, .5);
    }
  }
  .main-content{
    padding-top: 0;
  }
}
body.page-generic {
  //section {
  //  &#header {
  //    .logo-nav {
  //      //min-height: 70px;
  //      // background-image: url($image-url + "home/hero-home.png");
  //      background-size cover;

  //background-size: cover;
  //background-size: contain;
  //background-size: auto;
  //      //background-position: center center;
  //      background-color: rgba(0, 88, 88, 1);
  //    }
  //    &.scrolled {
  //      .logo-nav {
  //        background-image: none;
  //      }
  //
  //    }
  //  }
  //}

}
section {
  &#flash-message {
    $w:400px;
    $zi:1100;
    position: fixed;
    top:120px;
    right:0;
    //border:1px solid red;
    z-index: $zi;
    > .inner{
      z-index: $zi + 1;
      position: relative;
      top:0;
      right:0;
      > .alert{
        position: absolute;
        top:0;
        right:0;
        //right:-400px;
        transition: right .6s ease-out;
        max-width: $w;
        width: $w;
        //display: none;
      }
      > .toggle-buttons{
        button{background-color: color(pv);color: color(white); border: 0px none; }
        z-index: $zi + 1;
        position: absolute;
        top:0;
        right:0;
        width: 40px;
        height: 40px;
      }

    }

    &.pshow{
      .alert{right:0;}
      .toggle-buttons{
        #show-msg{display: none;}
        #hide-msg{display: block;}
      }
    }
    &.phide{
      .alert{right:-400px;}
      .toggle-buttons{
        #hide-msg{display: none;}
        #show-msg{display: block;}
      }
    }

  }
  &#header {
    position: fixed;
    width: 100%;
    z-index: 1002;
    .btn {

    }
    .container{
      // padding: 10px 15px;
    }
    .search-wrap {

      height: 0;
      overflow: hidden;
      transition: height 0.3s ease-in-out;
      background-color: color(light-gray);

      .container{
        padding: 0 15px;
      }

      //new search skyhigh style
      .search-form {
        //postion relative cause color issues on small devices
        //position: relative;
        background-color: color(light-gray);
        height: $navbar-height;
        z-index: 1;
        .form-group {
          height: $navbar-height;
          width: 100%;
          margin: 0 0;
          padding: 0 0;

          .form-control {
            width: inherit;
            height: $navbar-height;
            line-height: $navbar-height - 16px - 16px;
            vertical-align: middle;
            background-color: color(light-gray);
            border: none;
            box-shadow: none;
            text-align: right;
            padding: 16px $navbar-height 16px 0 ;
            z-index: 2;

            //width: 100%;
            //padding-left: 100px;
            //padding-right: 70px;
            //position: relative;
            //z-index: 100;
          }
        }
        .btn {
          $h: 40px;
          z-index: 3;
          padding: 0 0;
          color: color(pv) !important;
          [class*=" icon-"], [class^=icon-] {
            color: color(pv) !important;
            z-index: 4;
          }
          border:0 none;
          min-width: $h;

          background: none;
          content: "";
          width: $h;
          height: $h;
          position: absolute;
          //right: ($navbar-height - $h)/2;
          right: 10px;
          top: ($navbar-height - $h)/2;
          overflow: hidden;


          //position: absolute;
          //z-index: 102;
          //top: 5px;
          //right: 15px;
          //color: #FFF;

          -webkit-transition: opacity 0.3s ease-in-out;
          -moz-transition: opacity 0.3s ease-in-out;
          -o-transition: opacity 0.3s ease-in-out;
          -ms-transition: opacity 0.3s ease-in-out;
          transition: opacity 0.3s ease-in-out;
        }
      }

    }

    .logo-nav {
      position: initial;
      //.container .row{display: none;}
      transition: opacity 0.3s ease;

      //nav on hero panel
      //background-color: rgba(0, 88, 88, .5);
      background-color: color(pv);

      //nav on other panels panel

      .container {
        //padding: 10px 15px;
        position: relative;
        .logo {
          position: absolute;
          z-index: 101;
          top: 6px;
          left: 15px;
          //margin-top: 10px;
          a {
            display: block;
            z-index: 111;
          }
          img {
            height: 50px;
            transition: height 0.3s ease;
          }

        }
        //md+ devices
        @media all and (min-width: $md-min) {
          nav {
            // width: 100%;
            padding-left: 100px; /*for logo*/
            padding-right: 70px; /*for search button*/
            position: relative;
            z-index: 100;
          }
        }
        .search-trigger {
          min-width: initial;
          background-color: transparent;
          min-width: 40px;
          color: color(white);
          border:0 none;

        }

        //md+ devices
        @media all and (min-width: $md-min) {
          .search-trigger {
            //min-width: 70px;
            position: absolute;
            z-index: 102;
            top: 16px;
            right: 5px;
            color: color(white);
          }
        }
      }
    }


    //&#header has class search-open
    &.search-open {
      .search-wrap{
        height: $navbar-height;
      }

      .logo-nav {
        background-color: rgba(0, 88, 88, 0.7);
        //opacity: .8;
        //background-color: red;
      }

    }

    &.scrolled {
      .logo-nav {
        background-color: color(pv);
        .container {
          // padding: 7px 15px;
          .logo {
            //top: 6px;
            img {
              height: 45px;
            }
          }

          .search-trigger {
            top: 12px;
          }

        }
      }
      //background-color: rgba(0, 88, 88, .95);

      .pv-navbar-main {
        a, a:visited, a:hover {
          //padding: 8px 15px;
          font-size: 18px;
        }

        //sm+ devices
        @media all and (min-width: $lg-min) {
          li.active {
            margin-top: -8px;
            > a {
              padding-top: 8px + 8px - 3px;
              border-top: 3px solid color(pv);
            }
          }

        }
      }
    }

  }

  //xs+sm devices
  @media all and (max-width: $sm-max) {
    &#header {
      .logo-nav {
        max-height: $navbar-height;
        .container .logo img{
          height: 48px;
        }
      }
      &.scrolled {
        .logo-nav {
          max-height: $navbar-scrolled-height;
        }
      }
    }
    .search-trigger {
      position: relative;
      z-index: 102;
      top: 14px;
      right: 5px;
      color: color(white);
    }

  }
}
