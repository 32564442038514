//bootstrap theme for provalues
//any styles to reset bootstrap should go into this file
.form-horizontal .control-label {
  //todo start using align-self-center
  //padding-top: 15px;
  margin:0px;
}
.control-label{font-size: $font-size-base*.85;}
.form-control {
  font-size: $font-size-base;
}

.breadcrumb {
  margin-bottom: 0;
}

//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  .glyphicon{margin-right: 15px;}
  text-shadow: 0 1px 0 rgba(255, 255, 255, .2);
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 3px solid color(pv);
  font-weight: normal;
  font-family: Lato-Regular;
  .glyphicon {
    top: 3px;
  }
}

// Mixin for generating new styles
@mixin cia-alert-styles($color) {
  background-color: #fff;
  //border-color: lighten($color, 0%);
  border-color: #f3f3f3;
  color: darken($color, 0%);
}

//.alert-success    { @include cia-alert-styles(darken(color(pvd1), 10%)); }
.alert-success {
  @include cia-alert-styles(color(success));
}

.alert-info {
  @include cia-alert-styles(color(info));
}

.alert-warning {
  @include cia-alert-styles(color(warning));
}

.alert-danger {
  @include cia-alert-styles(color(error));
}

.panel-group {
  .panel {
    margin-bottom: 30px;
    border-radius: 0;
  }

}


.panel-group .panel-heading+.panel-collapse>.panel-body {
  border-top: 0 none;
}
.panel .panel-collapse.collapsing {
  //border:0 none;
}
.panel {
  box-shadow: none;
  border: none;
  a, a:visited {
    outline: none;
    text-decoration: none;
    user-select: none;

  }
  font-family: Lato-Regular;
  $border-width:2px;
  $border-color:color(gray);
  $border-color-open:color(pv);
  $padding:24px;
  $padding-xs:16px;
  border-radius: 0;
  background: white;
  //transition: all 0.1s ease-out;
  //color: color(pv);
  box-shadow: none;
  margin-top: 0 !important;
  //margin-bottom: 30px;

  &:last-of-type {
    .panel-heading {
      .panel-title {
        a {
          &[aria-expanded="false"] {
            //border-bottom: $border-width solid $border-color;

          }
          //border-bottom: $border-width solid $border-color;
        }
      }
    }
  }

  .panel-heading {
    background: white;
    padding: 0 0;
    border-color: transparent;
    border: none;
    .panel-title {
      padding: 0 0;
      font-family: Lato-Regular;
      font-size: $font-size-base*1.125;
      position: relative;
      > a {
        display: block;
        color: color(charcoal);
        border-left: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
        border-top: $border-width solid $border-color;
        border-bottom: $border-width solid $border-color;

        &[aria-expanded="false"] {}
        &[aria-expanded="true"] {
          //background: color(pv);
          //color: white;
          border-left: $border-width solid $border-color-open;
          border-right: $border-width solid $border-color-open;
          border-top: $border-width solid $border-color-open;
          border-bottom: none;
          &:before {
            content: "\f068";

          }
        }

        padding: $padding 70px $padding $padding;
        //xs devices
        @media all and (max-width: $xs-max) {
          padding: $padding-xs  70px $padding-xs $padding-xs;
        }
        width: 100%;
        &:before {
          font-family: FontAwesome;
          font-weight: normal;
          background-size: 28px;
          height: 28px;
          width: 28px;
          content: "\f067";
          position: absolute;
          top: 27px;
          right: 30px;
          //xs devices
          @media all and (max-width: $xs-max) {
            top: 19px;
          }
        }
      }
    }
  }

  .panel-collapse {
    a,
    a:focus,
    a:visited,
    a:hover {
      //color: color(white);
    }

    border-left: $border-width solid $border-color;
    border-right: $border-width solid $border-color;
    border-bottom: $border-width solid $border-color;

    &.collapsing {
      //background: color(pv);
      color: white;
      transition: all 0.1s ease;

    }
    &.collapse.in {
      border-left: $border-width solid $border-color-open;
      border-right: $border-width solid $border-color-open;
      border-bottom: $border-width solid $border-color-open;
      border-top: none;
      //background: color(pv);
      //color: white;
    }
    > .panel-body {
      padding: 0 $padding $padding $padding;
    }
    a {
      margin-bottom: 5px;
      //display: block;
      &:hover {
      }
    }
  }

  .list-group-item {
    border-radius: 0;
    position: relative;
    display: block;
    padding: 10px 15px;
    padding: 10px 0 ;
    margin-bottom: -1px;
    //background-color: color(pvl2);
    border: none;
  }

  a.list-group-item, button.list-group-item {

    &,
    &:focus,
    &:visited,
    &:hover {
      color: color(pv);
      background-color: initial;
    }
  }

  .list-group-item:first-child {
    border-radius: 0;
  }

  &.panel-pv { @include panelStyles(color(pv));}
  &.panel-info { @include panelStyles(color(pv));}
  &.panel-default { @include panelStyles(color(gray)); .panel-heading{ background-color: color(light-gray);}}
}


//http://croogo.sparreyconsulting.com/register
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
  // position: absolute;
  left:0;
  top:13px;
  margin-left: 0px;
  margin-top: 4px;
  margin-right: 5px;
}
