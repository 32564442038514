$i: 4;
@while $i < 33 {
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}px ;
  }
  $i: $i + 4;
}

.overflow-hidden{
  overflow: hidden;
}