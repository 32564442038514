* {
  outline: none !important;
}

body {
  //font-size: $font-size-base*1;
  //font-family: Lato-Light, sans-serif, Arial, Helvetica;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-size: $font-size-base;
  line-height: 1.2;
  margin: 0;
  font-weight: normal;
  //margin-bottom: 20px;

  &:not(:first-child) {
    //do not use
    //margin-top: $font-size-base *  1.5;
  }
}

h1 {
  font-size: $font-size-base *  2;
}

h2 {
  font-size: $font-size-base *  1.5;
}

h3 {
  font-size: $font-size-base *  1.25;
}

h4 {
  font-size: $font-size-base *  1.125;
}

h5 {
  font-size: $font-size-base *  1;
}

h6 {
  font-size: $font-size-base *  1;
}
h1, h2, h3, h4, h5, h6 {
  //margin-bottom: 0;
  &:last-child{padding-bottom: 0;}
  //padding-bottom: $font-size-base;
}
blockquote {
  padding: 0;
  margin: 0;
  border: none;
}
strong,.strong{font-family: Lato-Regular; font-weight:400;}
//h1 and all non button a tags to have pv color
//h2,
a:no t(.btn), a:not(.btn):visited, a:not(.btn):hover {
  color: color(pv);
  .bg-color-pv & {
    color: color(white);
  }
}
