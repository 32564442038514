//==Validation=============================
//base and fg are mandatory
@each $name, $colorSet in $colors {
  @if map-has-key($colorSet, base) {
  } @else {
    @warn "Unknown base color in `#{$name}` in colors.";
  }

  @if map-has-key($colorSet, fg) {
  } @else {
    @warn "Unknown fg color in `#{$name}` in colors.";
  }
}

//==end of Validation======================



@function color($key, $variant:base) {
  @if map-has-key($colors, $key) {
    @return map-get(map-get($colors, $key), $variant);
  }
  @warn "Unknown `#{$key}` in colors.";
  @return null;
}

@each $name, $colorSet in $colors {
  @if map-has-key($colorSet, base) {
    // text color
    .fg-color-#{$name} {
      color: map-get($colorSet, base);
    }

  } @else {
    @warn "Unknown base color in `#{$name}` in colors.";
  }
}

@each $name, $colorSet in $colors {
  @if map-has-key($colorSet, base) {
    // bg color
    .bg-color-#{$name} {
      background-color: map-get($colorSet, base);
    }
  } @else {
    @warn "Unknown base color in `#{$name}` in colors.";
  }
}
